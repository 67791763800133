import React from 'react'

const TableHeader = () => {
  return (
      <div className="container-fluid">
    <div className="row user_table ivr-grid-heads">
    <div className="col col-xl-3 col-lg-3 col-md-3 grid-instance-head-column ">
        <span className="gridHeadText">Web Instance</span>
    </div>
    <div className="col col-xl-2 col-lg-1 col-md-1 ivr-name-col-head">
        <span className="gridHeadText">IVR Name</span>
    </div>
    <div className="col col-xl-2 col-lg-2 col-md-2 ivr-version-col-head">
        <span className="gridHeadText">Version Name</span>
    </div>
    <div className="col col-xl-2 col-lg-3 col-md-3 grid-date-col-head">
        <span className="gridHeadText">Last Updated</span>
    </div>
    <div className="col col-xl-2 col-lg-2 col-md-1 grid-status-column pl-0">
        <span className="gridHeadText">Status</span>
    </div>
    <div className="col col-xl-1 col-lg-1 col-md-2 grid-action-column">
        <span className="gridHeadText">Actions</span>
    </div>
</div>
      </div>

  )
}

export default TableHeader;

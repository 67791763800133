import React, {useState, Component} from 'react';
import ModalDisplay from '../ModalDisplay/ModalDisplay';
import VersionModal from "../VersionModal/VersionModal";
import filterIcon from '../../assets/images/icons/filter_icon.png';
import FilterModal from '../FilterModal/FilterModal';
import VersionType from "../SmoothScroll/SmoothScroll";
import ClientList from "../SmoothScroll/ClientList";
import AddUserModal from "../AddUserModal/AddUserModal";
import RolesModal from "../RolesModal/RolesModal";
import StatusList from "../SmoothScroll/StatusList";
import IvrList from "../SmoothScroll/IvrList";
import RolesType from "../SmoothScroll/RolesType";

const IVRName = [
    { label: "CBTC", value: "CBTC" },
    { label: "Comerica", value: "Comerica" },
    { label: "Fair Fintech", value: "Fair Fintech" },
    { label: "CBTC", value: "CBTC1" },
    { label: "Neteller", value: "Neteller1" },
    { label: "CBTC", value: "CBTC2" },
    { label: "Comerica", value: "Comerica3" },
    { label: "Fair Fintech", value: "Fair Fintech4" },
    { label: "CBTC", value: "CBTC5" },
    { label: "Neteller", value: "Neteller6" },

];

const clientNameListVersion = [
    { label: "Standard IVR", value: "Standard IVR ver" },
    { label: "One Day IVR", value: "One Day IVR ver" },
    { label: "Credit Card IVR", value: "Credit Card IVR v" },
    { label: "Balance Inquiry IVR", value: "Balance Inquiry IVR ver" },
    { label: "Standard IVR", value: "Standard IVR1 vvver" },
    { label: "One Day IVR", value: "One Day IVR1 ver v" },
    { label: "Credit Card IVR", value: "Credit Card IVR1 verv" },
    { label: "Balance Inquiry IVR", value: "Balance Inquiry IVR1ver v" },
];



const Filter = ({ maxHeight, ...props }) => {


    const [open,setopen]=useState(false);

    const handleOpen = () => {
        setopen(true);
    }

    const handleClose = () => {
        setopen(false);
    }
    const [openVersion,setopenVersion]=useState(false);

    const handleOpenVersion = () => {
        setopenVersion(true);
    }

    const handleCloseVersion = () => {
        setopenVersion(false);
    }

    const [openFilter,setopenFilter]=useState(false);

    const handleOpenFilter = () => {
        setopenFilter(true);
    }

    const handleCloseFilter = () => {
        setopenFilter(false);
    }
    const [visibilityShow , setVisibilityShow] = useState(false);
    const setVisibility = () =>{
        setVisibilityShow(!visibilityShow);
    }
    const screensize = window.innerWidth;
    const [openUserModal , setOpenUserModal] = useState(false);
    const handleOpenUser = () =>{
        setOpenUserModal(true);
    }
    const [openRole , setOpenRole] = useState(false);
    const handleOpenRole = () => {
        setOpenRole(true);
    }
    const [versionMessage , setVersionMessage] = useState(false);
    const handleVersionMessage = () => {
        setVersionMessage(true);
    }
    const [openSearchBar , setOpenSearchBar] = useState(false);
    const handleOpenSearchBar = () => {
        setOpenSearchBar(!openSearchBar);
    }
  return (

      <>
       {/* <!-- Button for mobile view --> */}
          {  props.routeName === "VersionManagement" ?
              <>

                  <div className="options-tooltip">

                      <button type="button" className="btn btn-add-version icon-more-vertical"  onClick={screensize < 993 ? setVisibility : handleOpen} >
                      </button>
                      <div className={visibilityShow === true ? 'visibility-shown options-list' : 'options-list' } >
                          <button type="button" className="btn btn-add-version icon-filter-icon filter-btn" data-toggle="modal" data-direction='bottom' data-target="#filterModal" onClick={handleOpenFilter}>
                          </button>
                          <button type="button" className="btn btn-add-version icon-search_field search-btn" onClick={props.showSearchBox}>
                          </button>
                          <button type="button" className="btn btn-add-version icon-plus-icon add-btn" data-toggle="modal" data-target="#addIVR" onClick={handleOpen}>
                          </button>
                      </div>
                  </div>

              </>
              : props.routeName === "IVRManagement" ?
              <div className="options-tooltip">
              <button type="button" className="btn btn-add-version icon-more-vertical"  onClick={screensize < 993 ? setVisibility : handleOpenVersion} >
              </button>
                  <div className={visibilityShow === true ? 'visibility-shown options-list' : 'options-list' } >
                      <button type="button" className="btn btn-add-version icon-filter-icon filter-btn" data-toggle="modal" data-direction='bottom' data-target="#filterModal" onClick={handleOpenFilter}>
                      </button>
              <button type="button" className="btn btn-add-version icon-search_field search-btn" onClick={props.showSearchBox}>
              </button>
              <button type="button" className="btn btn-add-version icon-plus-icon add-btn" data-toggle="modal" data-target="#addVersion" onClick={handleOpenVersion}>
              </button>
                  </div>
                </div>
                  :
                  props.routeName === "UserRoles" ?
                          <div className="options-tooltip">

                          <button type="button" className="btn btn-add-version add-btn" data-toggle="modal" data-target="#rolesModal" onClick={handleOpenRole}><span className="icons-plus">+</span>
                          </button>
                          </div>
                          :
                      props.routeName === "UserManagement" ?

                          <div className="options-tooltip">
                          <button type="button" className="btn btn-add-version icon-more-vertical"  onClick={screensize < 993 ? setVisibility : handleOpenUser} >
                          </button>
                          <div className={visibilityShow === true ? 'visibility-shown options-list' : 'options-list' } >
                              <button type="button" className="btn btn-add-version icon-filter-icon filter-btn" data-toggle="modal" data-direction='bottom' data-target="#filterModal" onClick={handleOpenFilter}>
                              </button>
                              <button type="button" className="btn btn-add-version icon-search_field search-btn" onClick={props.showSearchBox}>
                              </button>
                              <button type="button" className="btn btn-add-version icon-plus-icon add-btn" data-toggle="modal" data-target="#addUserModal" onClick={handleOpenUser}>
                              </button>
                          </div>
                      </div>
                      :
                      ''
          }

    <div className="filtersCon">
        <div className="container-fluid">
      <div className="row">

          <div className={`col-lg-4 col-md-12 ${screensize > 992 ? 'p-0' : ''}`}>
          <div className="filterBox">
            <div className="filterHeading">
                {
                    props.routeName === "IVRManagement" ? <h1 className="pageTitle">
                        Version Management
                    </h1> : props.routeName === "VersionManagement" ?
                        <h1 className="pageTitle">
                            IVR Management
                        </h1> :
                        props.routeName === "UserManagement" ?
                        <h1 className="pageTitle">
                            User Management
                        </h1> :
                            props.routeName === "UserRoles" ?
                                <h1 className="pageTitle">
                                    Roles Management
                                </h1>
                            : ""

                }


            </div>
                <div className="filterIcon">
                    <button type="button" className="btn btn-filter-icon" data-toggle="modal" data-target="#filterModal" onClick={handleOpenFilter}>
                        <img src={filterIcon} alt="Filter Icon" className="img-fluid" />
                    </button>
                </div>
            </div>
          </div>

          <div className={`col-lg-8 col-md-12 `}>
              <div className={`pageFilters filterContainer ${props.changeRole === true ? "opacity-0" : ""}`}>
                  <form>
                      <div className="form-row">
                          {
                              props.routeName !== 'UserRoles' ?
                                  <div className="col-auto searchFieldCon">
                                      <form action="/search" method="get">
                                          <span className="icon-Union search-icon-control"></span>
                                          <input className={`form-control search-field `}   type="text"
                                                 placeholder="Search" title="Search" />

                                      </form>
                                  </div>
                                   : ""
                          }


                          {props.routeName === "IVRManagement" && (

                              <div className="col-auto">

                                  <VersionType />

                              </div> ) }
                          {props.routeName === "UserManagement" && (

                              <div className="col-auto">

                                  <RolesType />

                              </div> ) }

                          {props.routeName === "VersionManagement" && (

                                  <div className="col-auto col-auro1">

                                    <IvrList />

                                  </div>
                          )

                          }
                          {props.routeName === "UserManagement" && (

                              <div className={openSearchBar ? "col-auto col-auro1 custom-margin-right" : 'col-auto col-auro1'}>

                                  <StatusList />

                              </div>
                          )

                          }
                          { props.routeName === 'IVRManagement' && (
                          <div className="col-auto">

                              <StatusList className={openSearchBar ? "custom-margin-right" : null} />
                          </div>
                              )
                          }

                          { props.routeName === 'VersionManagement' && (
                              <div className={openSearchBar ? "col-auto custom-margin-right" : 'col-auto'}>

                                  <ClientList  />

                              </div>
                          )
                          }
                          { props.routeName === "VersionManagement" && (
                              <div className="col-auto addVersionBtnCon">
                                  <button type="button" className="btn btn-primary with-plus-icon" data-toggle="modal"
                                          id="addIVRbtn" data-target="#addIVR" onClick={handleOpen}>
                                      Add IVR
                                  </button>
                              </div>
                          )
                          }
                          {  props.routeName === "IVRManagement" && (
                              <div className="col-auto addVersionBtnCon">
                                  <button type="button" className="btn btn-primary with-plus-icon" data-toggle="modal"
                                          data-target="#addVersion" onClick={handleOpenVersion}>
                                      Add Version
                                  </button>
                              </div>
                          )
                          }
                          {  props.routeName === "UserManagement" ?
                              <div className="col-auto addVersionBtnCon">
                                  <button type="button" className="btn btn-primary with-plus-icon " data-toggle="modal"
                                          data-target="#addUserModal" onClick={handleOpenUser}>
                                      Add User
                                  </button>
                              </div> :
                              props.routeName === "UserRoles" ?
                                  <div className="col-auto addVersionBtnCon">
                                      <button type="button" className="btn btn-primary with-plus-icon " data-toggle="modal"
                                              data-target="#rolesModal" onClick={handleOpenRole}>
                                          Add Role
                                      </button>
                                  </div>



                              :
                              ''
                          }
                      </div>
                  </form>
              </div>
          </div>
      </div>
        </div>
        {openVersion ? <VersionModal /> : ''}
        {openUserModal ? <AddUserModal /> : ''}
        {open ? <ModalDisplay /> : ''}
        {openRole ? <RolesModal /> : ''}

        {openFilter ? <FilterModal close={handleCloseFilter} /> : ''}
  </div>
  </>
  );
}

export default Filter;

import React, {useState} from 'react';
import Filter from '../Filter/Filter';
import IVRManagement from './IVRManagament/IVRManagement';
import VersionManagement from './VersionManagement/VersionManagement';
import UserManagement from "./User/UserManagement";
import RolesManagement from "./User/RolesManagement";
const Main = (props) => {
  const [searchbar , showSearchbar] = useState(false);
  const displaySearch = () => {
    showSearchbar(!searchbar);
  }
  const [changeFilter, setChangeFilter] = useState(false);
  const handleChangeFilter = () => {
    setChangeFilter(true);
  }
  const handleChangeFilterFalse = () => {
    setChangeFilter(false);

  }
  return (
      <>

    <main >
    <div className={`container-fluid ${ props.class }`}>
        <Filter routeName={props.content} showSearchBox={props.searchProp} changeRole={changeFilter} />
        {props.content === "VersionManagement" ?
        <VersionManagement showSearchBar = { searchbar } searOptsProp={props.searOptProps}  closeSearchProp={props.closeProp}  searchElement={props.searchProp}  />
        :
            props.content === "IVRManagement" ?
            <IVRManagement showSearchBar = { searchbar } searOptsProp={props.searOptProps}  closeSearchProp={props.closeProp}  searchElement={props.searchProp}  />
        :
                props.content === "UserManagement" ?
                <UserManagement element={handleChangeFilter} element1={handleChangeFilterFalse} showSearchBar = { searchbar } searOptsProp={props.searOptProps}  closeSearchProp={props.closeProp}  searchElement={props.searchProp}  />
                    :
                    props.content === "UserRoles" ?

                        <RolesManagement />
                        : ""
        }
    </div>
    </main>
        </>
  )
}

export default Main;


import React from 'react';
import cibc from '../../assets/images/icons/cibc.png';
import client1 from '../../assets/images/icons/Greenphire.png';
import client2 from '../../assets/images/icons/pp.png';
const ClientsModal = (props) => {
    const screensize = window.innerWidth;

    return (
        <div className={`modal ivr-modal ${screensize > 992 ? "fade" : "fade-mob"}`} id="clientData" tabIndex="-1" role="dialog" aria-labelledby="ClientModalLabel"
             aria-hidden="true" style={{display: 'block'}}>
            <div className={`modal-dialog  ${screensize > 992 ? "modal-dialog-centered" : "modal-lg"} `} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title" id="addIVRLabel">Clients
                            <small>View the list of the clients user has access to..</small>
                        </h2>
                        <h2>
                            {props.dataID}
                        </h2>



                    </div>
                    <div className="modal-body">
                        <div className="clients-list">
                            <div className="client-holder">
                                <img src={cibc} alt="Client Icon" />
                                <span>Direct Express</span>
                            </div>
                            <div className="client-holder">
                                <img src={client1} alt="Client Icon" />
                                <span>Direct Express</span>
                            </div>
                            <div className="client-holder">
                                <img src={client2} alt="Client Icon" />
                                <span>Direct Express</span>
                            </div>
                            <div className="client-holder">
                                <img src={client1} alt="Client Icon" />
                                <span>Direct Express</span>
                            </div>
                            <div className="client-holder">
                                <img src={client1} alt="Client Icon" />
                                <span>Direct Express</span>
                            </div>
                            <div className="client-holder">
                                <img src={client1} alt="Client Icon" />
                                <span>Direct Express</span>
                            </div>
                            <div className="client-holder">
                                <img src={client1} alt="Client Icon" />
                                <span>Direct Express</span>
                            </div>
                            <div className="client-holder">
                                <img src={client1} alt="Client Icon" />
                                <span>Direct Express</span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" data-dismiss="modal">Done</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientsModal;

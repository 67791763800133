import React, {useState} from 'react';
import landingIcon from '../../assets/images/logo.svg';
import loginImg from '../../assets/images/login-animation.svg';

import { Link } from "react-router-dom";

const Landing = (props) => {


    const [switchOpen, setSwitchOpen] = useState(true);
  return (
    <div id="MainBox" className="container-fluid no-gutters login-container">


<div className="row align-items-center">
    <div className="col-lg-6 p-0">
        <div className="left-wrapper">
            <div className="content-wrapper">
                <h1>Welcome!</h1>
                <h3>Let's connect the Dots</h3>
                <p>Use the pre built templates or create your own IVR easily!</p>
            </div>
            <div className="img-wrapper">
                <img src={loginImg} alt="Login Animation" />

            </div>
            <div className="copyright-wrapper">
            <p className="copyrights-p">Copyright © i2c Inc. All Rights Reserved</p>
            </div>
            <span className="curls">

<svg width="316" height="687" viewBox="0 0 316 687" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M244.66 909.266L194.035 785.998C170.077 727.663 116.253 730.646 94.0482 676.579C71.5282 621.744 167.994 522.332 138.632 450.836C109.269 379.34 -11.8331 407.459 -40.9035 336.674C-62.2316 284.741 -27.6729 236.461 -50.0235 182.039C-74.4485 122.565 -98.5401 63.9032 -113.489 27.503" stroke="white" stroke-opacity="0.09" stroke-width="3"/>
<path d="M251.187 906.586L194.606 768.814C170.648 710.479 193.013 678.765 172.006 627.613C149.499 572.81 52.9513 552.629 23.5887 481.133C-5.77388 409.637 59.5979 361.173 30.5275 290.388C9.19948 238.456 -32.6962 221.575 -55.0468 167.152C-79.4718 107.679 -97.607 63.5202 -112.556 27.12" stroke="white" stroke-opacity="0.4"/>
<path d="M249.828 908.839L193.247 771.067C169.289 712.732 133.034 684.847 120.192 650.587C99.3967 595.11 126.97 523.926 97.6071 452.43C68.2445 380.933 12.0393 382.4 -17.0311 311.615C-38.3591 259.682 -30.4082 222.33 -52.7588 167.907C-77.1838 108.434 -95.319 64.2754 -110.268 27.8751" stroke="white" stroke-opacity="0.09" stroke-width="2"/>
</svg>

                </span>
        </div>
    </div>
    <div className="col-lg-6 ">
        <div className="centered_content">
        <main className="main-wrapper login-wrapper" role="main">
            <div className="login-head" >
            <a href="/" className="logo-area" title="IVR"> <img alt="Logo"
                                                                src={landingIcon} className="img-fluid" /></a>
            <span className="logo-desc">IVR</span>
            </div>
        <div className="login-box">
            <div id="Log">
                <h1>Log into Your Account
                </h1>
                <p>Please enter your User ID and Password to login</p>

                <form className="Floating form-wrapper" action="#">
                    <div className="form-field-group  p-relative">
                        <label htmlFor="UserName">User ID</label>
                        <input className="form-control" autoCorrect="off" autoCapitalize="off"
                               placeholder="Enter your user ID" type="text" name="User" id="UserName"
                               maxLength="150"/>
                    </div>

                    <div className="form-field-group  p-relative">
                        <label htmlFor="Password">Password</label>
                        <input className="form-control" placeholder="Enter your password" type="password"
                               name="Password" id="Password" autoComplete="off"/>
                    </div>

                    <div className="d-table w-100 mb-4 mt-2">
                        <div className="d-table-cell">
                            <label className="switch-label" htmlFor="rememberMe">
                                        <span className="switch-field">
                                            <input type="checkbox" id="rememberMe" className="switch-checkbox"
                                                   checked={switchOpen}/>
                                            <span className="slider round"
                                                  onClick={() => setSwitchOpen(!switchOpen)}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Remember?</span>


                                        </span>
                            </label>
                        </div>
                        <div className="d-table-cell text-right">
                            <Link to="/version" className="btn btn-blue">Sign
                                In
                            </Link>
                        </div>
                    </div>

                </form>
            </div>
        </div>
            <div className="copyright-wrapper">
                <p className="copyrights-p">Copyright © i2c Inc. All Rights Reserved</p>
            </div>
    </main>
        </div>

    </div>
</div>




</div>
  )
}

export default Landing;

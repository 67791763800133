import React, {useState} from 'react';
import deleteIcon from '../../assets/images/icons/Trash.png';


const DeleteModal = (props) => {


    return (
        <>
        <div className={`modal ivr-modal delete-modal fade`} id="deleteModal" tabIndex="-1"
             role="dialog" aria-labelledby="addIVRLabel" aria-hidden="true" style={{display: 'block'}}>
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">

                    </div>
                    <div className="modal-body">
                       <div className="delete-icon">
                           <img src={deleteIcon} alt="Delete icon" />
                       </div>
                        <h1>Delete Version?</h1>
                        <h3><b>Are you sure you want to delete this version?</b> This is irreversible action.</h3>


                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={props.element}>Yes, Delete</button>
                    </div>
                </div>
            </div>
        </div>

        </>
    )
}

export default DeleteModal;

import React from 'react';
import VersionPopup from "../SmoothScroll/VersionPopup";

const RolesModal = () => {
    const screensize = window.innerWidth;

    return (
        <div className={`modal ivr-modal  ${screensize > 992 ? "fade" : "fade-mob"}`} id="rolesModal" tabIndex="-1" role="dialog" aria-labelledby="AddRolesModal"
             aria-hidden="true" >
            <div className={`modal-dialog  ${screensize > 992 ? "modal-dialog-centered" : "modal-lg"} `} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title" id="addIVRLabel">Add New Role
                            <small>Fill in the form to define a new role in the system.</small>
                        </h2>

                        <div className="modalSwitchWrap">
                            <label className="custom-control-label" htmlFor="modalSwitch">Status</label>
                            <span className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="modalSwitch"/>
                        <label className="custom-control-label" htmlFor="modalSwitch"></label>
                    </span>
                        </div>

                    </div>
                    <div className="modal-body container-role roles-list-modal">
                        <form>
                            <div className="form-row">
                                <div className="col-12 col-md-12 dropDownField forms-field">
                                    <label htmlFor="roleName">Role Name <span
                                        className="mand-field">*</span></label>
                                    <input id="roleName" type="text" placeholder="Role Name"
                                           className="form-control"/>
                                </div>
                                <div className="col-12 col-md-12 dropDownField forms-field">
                                    <label htmlFor="client-Drop">Super Admin Access</label>
                                    <div className="roles-list client-role-list">
                                        <label htmlFor="selectAll" className="role-btns">
                                            <input id="selectAll" type="checkbox"
                                                   className="form-control" />
                                            <span>Select All</span>

                                        </label>

                                    </div>
                                </div>
                                <div className="col-12 col-md-12 dropDownField forms-field">
                                    <label htmlFor="client-Drop">Version Management</label>
                                    <div className="roles-list client-role-list">
                                        <label htmlFor="view" className="role-btns">
                                            <input id="view" type="checkbox"
                                                   className="form-control" />
                                            <span>View</span>

                                        </label>
                                        <label htmlFor="edit" className="role-btns">
                                            <input id="edit" type="checkbox"
                                                   className="form-control" />
                                            <span>Edit</span>

                                        </label>
                                        <label htmlFor="add" className="role-btns">
                                            <input id="add" type="checkbox"
                                                   className="form-control" />
                                            <span>Add</span>

                                        </label>
                                        <label htmlFor="backend" className="role-btns">
                                            <input id="backend" type="checkbox"
                                                   className="form-control" />
                                            <span>View Backend Tasks</span>

                                        </label>

                                    </div>
                                </div>
                                <div className="col-12 col-md-12 dropDownField forms-field">
                                    <label htmlFor="client-Drop">IVR Management</label>
                                    <div className="roles-list client-role-list">
                                        <label htmlFor="viewivr" className="role-btns">
                                            <input id="viewivr" type="checkbox"
                                                   className="form-control" />
                                            <span>View</span>

                                        </label>
                                        <label htmlFor="editivr" className="role-btns">
                                            <input id="editivr" type="checkbox"
                                                   className="form-control" />
                                            <span>Edit</span>

                                        </label>
                                        <label htmlFor="addivr" className="role-btns">
                                            <input id="addivr" type="checkbox"
                                                   className="form-control" />
                                            <span>Add</span>

                                        </label>

                                    </div>
                                </div>
                                <div className="col-12 col-md-12 dropDownField forms-field">
                                    <label htmlFor="client-Drop">User Management</label>
                                    <div className="roles-list client-role-list ">
                                        <label htmlFor="viewUsers" className="role-btns">
                                            <input id="viewUsers" type="checkbox"
                                                   className="form-control" />
                                            <span>View Users</span>

                                        </label>
                                        <label htmlFor="editUsers" className="role-btns">
                                            <input id="editUsers" type="checkbox"
                                                   className="form-control" />
                                            <span>Edit Users</span>

                                        </label>
                                        <label htmlFor="addUsers" className="role-btns">
                                            <input id="addUsers" type="checkbox"
                                                   className="form-control" />
                                            <span>Add Users</span>

                                        </label>
                                        <label htmlFor="deleteUsers" className="role-btns">
                                            <input id="deleteUsers" type="checkbox"
                                                   className="form-control" />
                                            <span>Delete Users</span>

                                        </label>
                                        <label htmlFor="viewRoles" className="role-btns">
                                            <input id="viewRoles" type="checkbox"
                                                   className="form-control" />
                                            <span>View Roles</span>

                                        </label>
                                        <label htmlFor="editRoles" className="role-btns">
                                            <input id="editRoles" type="checkbox"
                                                   className="form-control" />
                                            <span>Edit Roles</span>

                                        </label>
                                        <label htmlFor="addRoles" className="role-btns">
                                            <input id="addRoles" type="checkbox"
                                                   className="form-control" />
                                            <span>Add Roles</span>

                                        </label>
                                        <label htmlFor="deleteRoles" className="role-btns">
                                            <input id="deleteRoles" type="checkbox"
                                                   className="form-control" />
                                            <span>Delete Roles</span>

                                        </label>

                                    </div>
                                </div>

                            </div>




                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RolesModal;

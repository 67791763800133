import React from 'react';
import VersionPopup from "../SmoothScroll/VersionPopup";

const VersionModal = () => {
const screensize = window.innerWidth;

    return (
        <div className={`modal ivr-modal ${screensize > 992 ? "fade" : "fade-mob"}`} id="addVersion" tabIndex="-1" role="dialog" aria-labelledby="AddVersionLabel"
             aria-hidden="true" style={{display: 'block'}}>
            <div className={`modal-dialog  ${screensize > 992 ? "modal-dialog-centered" : "modal-lg"} `} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title" id="addIVRLabel">Add New Version
                            <small>Fill in the form to define a version in the system.</small>
                        </h2>



                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-row">
                                <div className="col-12 col-md-6 dropDownField forms-field">
                                    <label htmlFor="versionName">Version Name <span
                                        className="mand-field">*</span></label>
                                    <input id="versionName" type="text" placeholder="Version Name"
                                           className="form-control"/>
                                </div>
                                <div className="col-12 col-md-6 dropDownField forms-field">
                                    <label htmlFor="client-Drop">Version Type <span className="mand-field">*</span></label>
                                   <VersionPopup />
                                </div>
                                <div className="col-12 textAreaField forms-field">
                                    <label htmlFor="description">Description <span
                                        className="mand-field">*</span></label>
                                    <textarea id="description" placeholder="Enter Message"
                                              className="form-control"></textarea>
                                </div>
                            </div>




                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-primary">Proceed</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VersionModal;

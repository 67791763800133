import React from "react";
import Select, { components } from "react-select";
import { Scrollbar } from "smooth-scrollbar-react";
import "./SmoothScroll.css";

const clientNameListIvr = [
    { label: "Saved", value: "Saved" },
    { label: "Draft", value: "Draft" },
];
const selectStyle = {
    container: (css) => ({
        ...css,
        transition: "0.4s ease",
        "&:focus": {
            backgroundColor: '#fff',
            borderColor: 'red',
        }
    }),
    control: (css , { data, isDisabled, isFocused, isSelected }) => ({ ...css,
        backgroundColor: isFocused ? '#fff' : '#FFFFFF;',
        textAlign: 'left',
        fontSize: '0.833vw',
        color: '#2D2F32',
        left: '0.139vw',
        minHeight: '2.778vw',
        verticalAlign: 'middle',
        cursor: "pointer",
        borderRadius: '0.417vw',
        width: 'auto',
        padding: '0 2.639vw 0 0.208vw',
        boxShadow: '0px 0.278vw 0.556vw rgba(0, 0, 0, 0.01)',
        borderColor: isFocused ? '#39B7E9' : '#DBDBDB',

        "&:hover": {

        },
        "&:focus": {
            outline: '0',
            borderColor: '#525252',
            backgroundColor: '#fff',
        }
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
        ...styles,
        color: '#2D2F32',
        textAlign: 'left',
        cursor: 'pointer',
        backgroundColor: isSelected ? "rgb(224,224,224)" : '#fff',
        padding: '0.833vw 1.111vw',

        fontSize: '0.833vw',
        width: '100%',
        "&:hover": {
            backgroundColor: "rgba(249, 249, 249, 1)",
        },


    }),
    placeholder: () => ({
        color: 'black',
        paddingTop: '0.139vw',
        "&:focus": {
            backgroundColor: '#fff',
            borderColor: 'red',
        }
    }),
    valueContainer: () => ({
        display: 'flex',
        alignItems: 'center',
        padding: '0.069vw 0 0 0.903vw',
    }),
    singleValue: () => ({
        color: '#000'
    }),
    dropdownIndicator: () => ({
        background: "url('../../assets/images/caret_drop.svg') no-repeat",
        position: 'absolute',
        right: '0.833vw',
        height: '0.833vw',
        width: '0.833vw',
        backgroundSize: 'cover',

        "& svg": {
            display: "none",
        }
    }),
    menu: (base) => ({
        ...base,

        marginLeft: "0.069vw",
    }),
    menuList: (base) => ({
        ...base,

        border: '0',
        outline: '0',
        borderRadius: '0.347vw',
        scrollMarginTop: '0px',
        paddingTop: '0.694vw',
        width: '100%',
        paddingBottom: '0',
        overflow: 'hidden',
        margin: '0.139vw 0',
        boxShadow: '0px 0.288vw 0.576vw rgba(0, 0, 0, 0.07)',

    }),



};
const MenuList = ({ maxHeight, ...props }) => {

    return (
        <components.MenuList {...props}>
            <Scrollbar
                plugins={{
                    overscroll: {
                        effect: "bounce"
                    }
                }}
            >
                <div>
                    <div style={{ maxHeight: 212 }}>{props.children}</div>
                </div>
            </Scrollbar>
        </components.MenuList>
    );
};

export default function StatusList (props) {
    return <Select options={clientNameListIvr} isSearchable={false} styles={selectStyle} placeholder={"Status"} title="Status" className={props.className}  components={{ MenuList , IndicatorSeparator: () => null }} />;
}

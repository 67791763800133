import React, {useState} from 'react';
import VersionPopup from "../SmoothScroll/VersionPopup";
import deleteIcon from "../../assets/images/icons/Trash.png";

const PublishModal = (props) => {
    const screensize = window.innerWidth;
    const [showSchedule, setShowSchedule] = useState(false);
    return (
        <div className={`modal ivr-modal delete-modal publish-modal ${screensize > 992 ? "fade" : "fade-mob"}`} id="publishModal" tabIndex="-1" role="dialog" aria-labelledby="PublishModal"
             aria-hidden="true" >
            <div className={`modal-dialog  ${screensize > 992 ? "modal-dialog-centered" : "modal-lg"} `} role="document">
                <div  className={`modal-content `}>

                    <div className="modal-body schedule-changes">
                        <div className="schedule-icon publish-icon">
                            <span className="icon-upload-icon"></span>
                        </div>
                        <h1>Which server would you like to publish on?</h1>
                        <div className="col-12 col-md-12 forms-field container-role upload-btns-radio">
                            <div className="roles-list ">
                                <label htmlFor="qa" className="role-btns">
                                    <input id="qa" type="radio"
                                           className="form-control" name="roles-btn" />
                                    <span>QA</span>

                                </label>
                                <label htmlFor="production" className="role-btns" >
                                    <input id="production" type="radio"
                                           className="form-control" name="roles-btn"/>
                                    <span>Production</span>

                                </label>
                                <label htmlFor="staging" className="role-btns" >
                                    <input id="staging" type="radio"
                                           className="form-control" name="roles-btn"/>
                                    <span>Staging</span>

                                </label>
                            </div>

                        </div>
                        <div className="text-with-bg">
                            <h4>Selected Version <span>Credit IVR Version, 24 Nov, 11:30AM  </span></h4>
                        </div>

                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-toggle="modal"
                                data-target="#scheduleModal" data-dismiss="modal" onClick={props.element}>Schedule</button>
                        <button type="button" className="btn btn-primary" data-dismiss="modal">Publish</button>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default PublishModal;

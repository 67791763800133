import React, {useState} from 'react';
import i2cLogo from '../../assets/images/logo.svg';
import Avatar from '../../assets/images/icons/av-mob.png';
import Notification_Icon from '../../assets/images/notification.png';
import HeaderBar from "../HeaderBar/HeaderBar";

const Header = (props) => {



    return (

    <header>
                <div className="container-fluid logo-n-avatar-wrapper header-generic">
                    <div className="row align-items-center">
                        <div className="col-sm-12 col-md-12 col-12">
                            <div className="divider-mob"></div>
                            <div className="logoWrapper">

                                <a href="/" className="logo-link">
                                    <img src={i2cLogo} alt="i2c Logo" />
                                    <span >IVR</span>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div className="main-header">
                    <HeaderBar />
                    </div>

                </div>
            </header>
  )
}

export default Header;

import React, {useState} from 'react';
import Header from '../Header/Header';
import Main from '../Main/Main';
import Sidebar from '../Sidebar/Sidebar';

const Content = (props) => {
    const [sidebarbtn, setsidebarbtn] = useState(false);
    const handleOnHover = () => {
        setsidebarbtn(!sidebarbtn);
    }
    const handleOutHover = () => {
        setsidebarbtn(false);
    }
    return (
    <>
        <Sidebar sidebarBtn={handleOnHover} setSideBarBtn={handleOutHover} element={sidebarbtn} />

        <div id={`content`} >
            <Header element1={handleOnHover} element2={handleOutHover} element3={sidebarbtn} />
          <Main  class={props.class} searOptProps={props.searOptProp} closeProp={props.closeSearch} content={props.data} searchProp={props.searchOptionProp} />
        </div>
    </>
  )
}

export default Content

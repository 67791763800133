import React from 'react'

const TableHeaderUser = () => {
    return (
        <div className="container-fluid">
        <div className="row user_table ivr-grid-heads">
            <div className="col col-xl-1 col-lg-1 col-md-1 ">
                <span className="gridHeadText">ID</span>
            </div>
            <div className="col col-xl-2 col-lg-2 col-md-2 user-grid">
                <span className="gridHeadText">User</span>
            </div>
            <div className="col col-xl-2 col-lg-2 col-md-2 role-type">
                <span className="gridHeadText">Role Type</span>
            </div>
            <div className="col col-xl-1 col-lg-1 col-md-1 contact-no">
                <span className="gridHeadText">Contact Number</span>
            </div>
            <div className="col col-xl-2 col-lg-2 col-md-2 clients-cust">
                <span className="gridHeadText">Clients</span>
            </div>
            <div className="col col-xl-2 col-lg-2 col-md-2 last-activity-grid">
                <span className="gridHeadText">Last Activity</span>
            </div>
            <div className="col col-xl-1 col-lg-1 col-md-1 actions-grid ">
                <span className="gridHeadText">Actions</span>
            </div>
            <div className="col col-xl-1 col-lg-1 col-md-1 status-grid">
                <span className="gridHeadText">Status</span>
            </div>
        </div>
        </div>
    )
}

export default TableHeaderUser;

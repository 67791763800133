import React, {useState, useEffect} from 'react'
import DataRow from './DataRow.js/DataRow';
import TableHeader from './TableHeader.js/TableHeader';
import icon from "../../../assets/images/icons/instance_img_1.png";
import icon1 from "../../../assets/images/icons/instance_img_2.png";
import icon2 from "../../../assets/images/icons/instance_img_3.png";
import ScheduleModal from "../../ScheduleModal/ScheduleModal";
import PublishModal from "../../PublishModal/PublishModal";
import Onebox from "../IVRManagament/OneBox/Onebox";


const VersionManagement = (props) => {

  const [getData, setGetData] = useState([]);


    const dataRows = [
      {
        id: 1,
        name: "CMA Versioning876",
        ivr_name: "Standard IVR",
        icons: icon,
        version: "Balance & CSV IVR 4",
        last_updated: "Nov 4 2021, 10:15 PM",
        publish: "published",
        stateSwitch: true,
      },
      {
        id: 2,
        name: "Aeldra Versioning976",
        ivr_name: "One Day IVR",
        icons: icon1,
        version: "IVRU Version",
        last_updated: "Nov 4 2021, 10:15 PM",
        publish: "save",
        stateSwitch: false,
      },
      {
        id: 3,
        name: "Fair Versioning346",
        ivr_name: "Credit Card IVR",
        icons: icon2,
        version: "Credit Card IVR",
        last_updated: "Nov 4 2021, 10:15 PM",
        publish: "save",
        stateSwitch: true,
      },
      {
        id: 4,
        name: "CMA Versioning876",
        ivr_name: "Balance Inquiry IVR",
        icons: icon,
        version: "Balance & CSV IVR 4",
        last_updated: "Nov 4 2021, 10:15 PM",
        publish: "save",
        stateSwitch: true,
      },
      {
        id: 5,
        name: "Aeldra Versioning976",
        ivr_name: "Standard IVR",
        icons: icon1,
        version: "IVRU Version",
        last_updated: "Nov 4 2021, 10:15 PM",
        publish: "published",
        stateSwitch: false,
      },
      {
        id: 6,
        name: "Fair Versioning346",
        ivr_name: "One Day IVR",
        icons: icon2,
        version: "Credit Card IVR",
        last_updated: "Nov 4 2021, 10:15 PM",
        publish: "save",
        stateSwitch: true,
      },
      {
        id: 7,
        name: "CMA Versioning876",
        ivr_name: "Credit Card IVR",
        icons: icon,
        version: "Balance & CSV IVR 4",
        last_updated: "Nov 4 2021, 10:15 PM",
        publish: "published",
        stateSwitch: true,
      },
      {
        id: 8,
        name: "Aeldra Versioning976",
        ivr_name: "Balance Inquiry IVR",
        icons: icon1,
        version: "IVRU Version",
        last_updated: "Nov 4 2021, 10:15 PM",
        publish: "save",
        stateSwitch: false,
      },
      {
        id: 9,
        name: "Fair Versioning346",
        ivr_name: "Credit Card IVR",
        icons: icon2,
        version: "Credit Card IVR",
        last_updated: "Nov 4 2021, 10:15 PM",
        publish: "published",
        stateSwitch: true,
      },
      {
        id: 10,
        name: "Fair Versioning346",
        ivr_name: "Credit Card IVR",
        icons: icon2,
        version: "Credit Card IVR",
        last_updated: "Nov 4 2021, 10:15 PM",
        publish: "save",
        stateSwitch: true,
      },
      {
        id: 11,
        name: "Fair Versioning346",
        ivr_name: "Credit Card IVR",
        icons: icon2,
        version: "Credit Card IVR",
        last_updated: "Nov 4 2021, 10:15 PM",
        publish: "save",
        stateSwitch: true,
      },
      {
        id: 12,
        name: "Fair Versioning346",
        ivr_name: "Credit Card IVR",
        icons: icon2,
        version: "Credit Card IVR",
        last_updated: "Nov 4 2021, 10:15 PM",
        publish: "published",
        stateSwitch: true,
      },
      {
        id: 13,
        name: "Fair Versioning346",
        ivr_name: "Credit Card IVR",
        icons: icon2,
        version: "Credit Card IVR",
        last_updated: "Nov 4 2021, 10:15 PM",
        publish: "save",
        stateSwitch: true,
      },
    ];

  const [openScheduleModal , setOpenScheduleModal] = useState(false);
  const handleOpenScheduleModal = () => {
    setOpenScheduleModal(true);
  }
  const [openPublishModal , setOpenPublishModal] = useState(false);
  const handleOpenPublishModal = () => {
    setOpenPublishModal(true);
  }
  const [searchData , setSearchData] = useState([]);
  const handleSearch = (event , id , allData) => {
    let searchQuery = event.target.value.toLowerCase();
    let displayedData = allData.filter(function(el) {
      let searchValue = el.name.toLowerCase();
      return searchValue.indexOf(searchQuery) !== -1;
    });
    if(event.target.value == ""){
      displayedData = [];
    }

    setSearchData(displayedData);
  }
  return (
    <>
      <div className="bordered-table">
    <TableHeader />
      <div className="user-management ivr-management">


          <>


                      <DataRow data={dataRows} closeElement={props.closeSearchProp} searchOpt={props.searOptsProp} handleSearchElement={handleSearch} element={handleOpenScheduleModal} element1={handleOpenPublishModal} />
          </>
      </div>

        <div className={`search-body ${props.searOptsProp ? "show" : ""}`}>
          {
            searchData.length > 0 ?
                    <div className="user-management ivr-management">
                    <DataRow data={searchData} element={handleOpenScheduleModal} element1={handleOpenPublishModal} />
                    </div>
                : ""
          }
        </div>
      </div>
      {
       openScheduleModal ? <ScheduleModal /> : ""
      }
      {
        openPublishModal ? <PublishModal element={handleOpenScheduleModal} /> : ""
      }
      {/* <DataRowUser />
    <DataRowUser /> */}
    </>
  )
}

export default VersionManagement;

import React, {useEffect, useRef, useState} from 'react';
import Onebox from './OneBox/Onebox';
import DeleteModal from "../../DeleteModal/DeleteModal";
import VersionManagement from "../VersionManagement/VersionManagement";

const IVRManagement = (props) => {

    const [gridData, setGridData] = useState([]);
    const {innerWidth: width} = window;
    let dataItems = [
    {
      id: 0,
      version_name: "Standard Version",
      data: [
        {
          id: 1,

          type: "draft",
          heading: "CS IVR Greeting",
          content:
              "Transfers call directly to CSR after playing greeting message",
          modified_on: "Nov 4 2021, 10:15PM",
        },
        {
          id: 2,

          type: "saved",
          heading: "CS IVR with Language Selection",
          content: "Transfers call to language specific CSR",
          modified_on: "Nov 4 2021, 10:15PM",
        },
        {
          id: 3,

          type: "draft",
          heading: "Credit IVR Versions",
          content: "Contains all credit specific states",
          modified_on: "Nov 4 2021, 10:15PM",
        },
        {
          id: 4,
          type: "saved",
          heading: "Credit IVR Version",
          content:
              "Account Information, ACH Menu, Share Funds, Change PIN, Activate Card ...",
          modified_on: "Nov 4 2021, 10:15PM",
        },
        {
          id: 5,

          type: "saved",
          heading: "CS IVR with Language Selections",
          content: "Transfers call to language specific CSR",
          modified_on: "Nov 4 2021, 10:15PM",
        },
        {
          id: 6,

          type: "draft",
          heading: "CS IVR Greetings",
          content: "Transfers call directly to CSR after playing greeting message",
          modified_on: "Nov 4 2021, 10:15PM",
        },
      ],
    },
    {
      id: 1,
      version_name: "CSR Version1",
      data: [
        {
          id: 1,

          type: "draft",
          heading: "IVRU Version1",
          content:
              "Account Information, ACH Menu, Share Funds, Change PIN, Activate Card, Rsest PIN",
          modified_on: "Oct 17 2021, 11:38 PM",
        },
        {
          id: 2,

          type: "draft",
          heading: "Credit IVR Version1",
          content: "Contains all credit specific states",
          modified_on: "Oct 17 2021, 11:38 PM",
        },
        {
          id: 3,

          type: "saved",
          heading: "Enhanced IVRU1",
          content: "Re-issue Card, Lost Stolen Menu",
          modified_on: "Oct 17 2021, 11:38 PM",
        },
        {
          id: 4,

          type: "saved",
          heading: "CMA IVR1",
          content: "IVR Version with client customized flow",
          modified_on: "Oct 17 2021, 11:38 PM",
        },
        {
          id: 5,

          type: "draft",
          heading: "CS IVR Greetings1",
          content:
              "Transfers call directly to CSR after playing greeting message",
          modified_on: "Oct 17 2021, 11:38 PM",
        },
        {
          id: 6,

          type: "saved",
          heading: "CS IVR with Language Selection1",
          content: "Transfers call to language specific CSR",
          modified_on: "Oct 17 2021, 11:38 PM",
        },
      ],
    },
      {
        id: 2,
        version_name: "GRV Version",
        data: [
          {
            id: 1,

            type: "draft",
            heading: "IVRU Version2",
            content:
                "Account Information, ACH Menu, Share Funds, Change PIN, Activate Card, Rsest PIN",
            modified_on: "Oct 17 2021, 11:38 PM",
          },
          {
            id: 2,

            type: "draft",
            heading: "Credit IVR Version2",
            content: "Contains all credit specific states",
            modified_on: "Oct 17 2021, 11:38 PM",
          },
          {
            id: 3,

            type: "saved",
            heading: "Enhanced IVRU2",
            content: "Re-issue Card, Lost Stolen Menu",
            modified_on: "Oct 17 2021, 11:38 PM",
          },
          {
            id: 4,

            type: "saved",
            heading: "CMA IVR2",
            content: "IVR Version with client customized flow",
            modified_on: "Oct 17 2021, 11:38 PM",
          },
          {
            id: 5,

            type: "draft",
            heading: "CS IVR Greeting2",
            content:
                "Transfers call directly to CSR after playing greeting message",
            modified_on: "Oct 17 2021, 11:38 PM",
          },
          {
            id: 6,

            type: "saved",
            heading: "CS IVR with Language Selection2",
            content: "Transfers call to language specific CSR",
            modified_on: "Oct 17 2021, 11:38 PM",
          },
        ],
      },
  ];
    useEffect(() => {
          setGridData(dataItems);
      },[]);
  const handleChange = () => {

  }
  const [searchData , setSearchData] = useState([]);
  const [searchIcon , setSearchIcon] = useState("search-icon");
  const handleSearch = (event , id , allData) => {

    let searchQuery = event.target.value.toLowerCase();
    let displayedData = allData.data.filter(function(el) {
      let searchValue = el.heading.toLowerCase();
      return searchValue.indexOf(searchQuery) !== -1;
    });
    setSearchIcon("icon-Union1");
    if(event.target.value == ""){
      displayedData = [];
      setSearchIcon("search-icon");
    }

    setSearchData(displayedData);
  }
  const [openDeleteModal , setOpenDeleteModal] = useState(false);

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  }
  const [deleteVersionMessage, setDeleteVersionMessage] = useState(false);
  const handleDeleteVersionMessage = () => {

     setDeleteVersionMessage(true);
    setTimeout(() => {
      setDeleteVersionMessage(false);
    }, 5000);
  }
  const [duplicateMessage , setDuplicateMessage] = useState(false);
  const handleDuplicateMessage = () => {
    setDuplicateMessage(true);
    setTimeout(() => {
      setDuplicateMessage(false);
    }, 5000);
  }


  return (
      <>

        <ul className="nav nav-pills mb-3 nav-display version-pills" id="pills-tab" role="tablist">
          {gridData.map((data) => (

            <li className="nav-item">
              <a className={`nav-link nav-heading ${data.id==0 ? 'active' : ''}`}  id={`version-management-tab ${data.id}`} data-toggle="pill"
                  href={`#ab${data.id}`} role="tab" aria-controls="version-management"
                  aria-selected="true">{data.version_name}</a>
          </li>
          ))}
        </ul>

        <div class="tab-content " id="pills-tabContent">
        {gridData.map((displayedData) => (
            <div key={displayedData.id} class={`tab-pane ${(width < 767 ? 'fade' : '')} ${displayedData.id==0 ? 'show active' : ''}`} id={`ab${displayedData.id}`} role="tabpanel">
            <h2 className="sub-heading">{displayedData.version_name}</h2>
            <div className="rows-con" >
              <div className={`search-container-main ${props.searOptsProp ? 'd-block' : 'd-none'}`}>
                <div className="search-cont search-show"><input type="text" onChange={event => handleSearch(event,  displayedData.id , displayedData)} className="form-control search-bar" placeholder="Search by name" />
                  <span className={searchIcon}></span>
                  <span className={`toggle-filter`} onClick={() => props.closeSearchProp()} ></span>
                </div>

              </div>
              {/*<div className={`search-container-main` }>*/}
              {/*  <div className={`search-cont ${props.showSearchBar === true ? 'search-show' : ''}`}>*/}
              {/*<input type="text" className={`form-control search-bar` } placeholder="Search by name" onChange={event => handleSearch(event,  displayedData.id , displayedData)} />*/}
              {/*<span className="search-icon" ></span>*/}
              {/*  </div>*/}
              {/*  <div className="search-body">*/}
              {/*    {searchData.map((data) => {*/}
              {/*        <Onebox key={data.id} element={handleOpenDeleteModal} duplicateMessage={handleDuplicateMessage} heading={data.heading} content={data.content} modifiedOn={data.modified_on} type={data.type} />*/}
              {/*    })}*/}
              {/*  </div>*/}
              {/*</div>*/}

              {

                displayedData.data.map((subData) => (
                    <>

                    <Onebox element={handleOpenDeleteModal} duplicateMessage={handleDuplicateMessage} key={subData.id} name={subData.name} id={subData.id} heading={subData.heading} content={subData.content} modifiedOn={subData.modified_on} type={subData.type} />
                    </>
                ))}
            </div>
            </div>

          ))}
          <div className={`search-body ${props.searOptsProp ? "show" : ""}`}>
            {
              searchData.length > 0 ?
              searchData.map((searchedData) => (
                <Onebox key={searchedData.id} name={searchedData.name} id={searchedData.id} heading={searchedData.heading} content={searchedData.content} modifiedOn={searchedData.modified_on} type={searchedData.type} />
              ))
                  : ""
            }
          </div>
          <div className={`message-alert ${deleteVersionMessage === true ? "show-message" : ""}`} >
            <span className="icon-check-circle"></span>
            <span className="message-text">Version has been deleted successfully.</span>
            <a href="javascript:void(0)" { ...( { onClick: () => setDeleteVersionMessage(false) } ) } className="icon-Union1"></a>
          </div>
          <div className={`message-alert ${duplicateMessage === true ? "show-message" : ""}`}>
            <span className="icon-check-circle"></span>
            <span className="message-text">Version has been duplicated successfully.</span>
            <a href="javascript:void(0)" { ...( { onClick: () => setDuplicateMessage(false) } ) } className="icon-Union1"></a>
          </div>
          </div>
        {openDeleteModal ? <DeleteModal element={handleDeleteVersionMessage} makeItFalse={deleteVersionMessage} /> : ''}

      </>

  )
}

export default IVRManagement;

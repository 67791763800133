import React, {useEffect, useState} from 'react';
import clockImage from '../../../../assets/images/clock.svg';

const DataRow = (props) => {
    const [dropCaret, setDropCaret] = useState(0);

    const handleDropCaret = (e) => {
        setDropCaret(e);
    }
    const handleDropCaretDesk = () => {
    }
    const redeneredItems = props.data.map((item, index)=>{

        return(
            <>
                {
                    index == 1 ?
                        <div className={`search-container-main ${props.searchOpt ? 'd-block' : 'd-none'}`}>
                            <div className="search-cont search-show"><input type="text" onChange={event => props.handleSearchElement(event,  item.id , props.data)} className="form-control search-bar" placeholder="Search by name" />
                                <span className="search-icon"></span>
                                <span className={`toggle-filter`} onClick={() => props.closeElement()} ></span>
                            </div>
                        </div>
                        : ""
                }

        <div className="container-fluid">
            <div className={`row user_table ${window.innerWidth > 992 ? item.id === dropCaret  ? 'pb-0 bordered-box' : '' : ''}`} onClick={ () => window.innerWidth < 992 ? item.id === dropCaret ? handleDropCaret(0) : handleDropCaret(item.id) : handleDropCaretDesk}>

                <div className="col col-xl-3 col-lg-3  col-md-12 col-sm-12 order-lg-1 order-md-3 col-12 order-3 grid-instance-column">
 <span tabIndex="0" className={`dropdownList d-none d-md-block avatar-dropdown-toggle grid-drop-caret ${item.id === dropCaret ? 'grid-drop-caret-down' : ''}`} onClick= { () => item.id === dropCaret ? handleDropCaret(0) : handleDropCaret(item.id) } >
                   <span className="icon-chevron-stroke"></span>
                </span>
                    <img src={item.icons} alt="instance_img_1" />
                    <span className="grid-reg-title"><a href="#">{item.name}</a></span>
                </div>
                <div className="col col-xl-2 col-lg-1 col-md-6 col-sm-6 order-lg-2 order-md-4 col-6 order-4 ivr-name-col">
                    <div className="mobile-flex">
                        <span className="responsiveTitle">IVR Name: </span>
                        <span className="grid-reg-text">{item.ivr_name}</span>
                    </div>
                </div>
                <div className="col col-xl-2 col-lg-2 col-md-6 col-sm-6 order-lg-3 order-md-5 col-6 order-5 version-name-col">
                    <div className="mobile-flex">
                        <span className="responsiveTitle">Version Name: </span>
                        <span className="grid-reg-text">{item.version}</span>
                    </div>
                </div>
                <div className="col col-xl-2 col-lg-2  col-md-10 col-sm-10 align-items-sm-end align-items-start align-items-md-start order-lg-5 order-md-1 col-10 order-1 grid-status-col p-0 " >
                    <div className="mobile-flex centered_content">
                        <span className="responsiveTitle">Status: </span>
                        <span className={`ivr-grid-status ${ item.publish.replace(/\s/g, '').toLowerCase()}`} >{item.publish}</span>
                    </div>
                </div>
                <div className="col col-xl-2 col-lg-3  col-md-8 col-sm-8 order-lg-4 order-md-11 col-8 order-11  grid-date-col time-with-icon">
                    <div className="mobile-flex">
                        <span className="responsiveTitle">Last Updated: </span>
                        <span className="grid-date-col-icon grid-reg-text ">
                                <img src={clockImage} alt="clock" />

                            <time dateTime="2021-11-04 20:15">{item.last_updated}</time>

            </span>
                    </div>
                </div>
                {/* Modal */}
                <div className="col-md-2 col-lg-1 col-sm-2 col-1 order-lg-6 order-md-2 col-2 order-2 icon-mobile ">
                    <div className="actionBtns">
                        <div className="actionIcons more-icon-con">

                <span className="custom-control custom-switch notmodal">
                            <input aria-label="Switch Element" type="checkbox" className="custom-control-input"
                                   id={"customSwitch" + item.id + "1" }  />
                            <label className="custom-control-label" htmlFor={"customSwitch" + item.id + "1"}></label>
                        </span>
                            <div className="dropdown">
                                <button tabIndex="0" className="icon-more-vertical" id={`versionDropDown1${item.id}`} title="icon-more-vertical"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                </button>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby={`versionDropDown1${item.id}`}>
                                    <a className="dropdown-item option-has-icon" href="javascript:void(0)" data-toggle="modal"
                                       data-target="#scheduleModal" type="button" onClick={props.element}><span className="icon-calender-icon-new"></span><p>Schedule</p></a>
                                    <a className="dropdown-item option-has-icon" href="javascript:void(0)" data-toggle="modal"
                                       data-target="#publishModal" type="button" onClick={props.element1}><span className="icon-upload-icon"></span><p>Publish</p></a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>



                {/* //Data */}
                <div className={`row row-details  ${ item.id === dropCaret  ? "visible-class" : "not-visible"}`} >
                    <div
                        className="col col-xl-3 col-md-6 col-lg-3 col-sm-6 col-6 order-6 grid-instance-column custom-padding">
                        <div className="sup-lang-wrap">

                            <div className="detail-text mobile-flex ">
                                <div className="detail-label">Supported Language</div>
                                <div className="detail-desc">English</div>
                            </div>
                        </div>
                    </div>


                    <div className="col col-xl-2 col-lg-1 col-md-6 col-sm-6  col-6 order-7 pl-10 ivr-name-col">
                        <div className="sup-lang-wrap">
                            <div className="detail-text mobile-flex grid-reg-text">
                                <div className="detail-label">Default Language</div>
                                <div className="detail-desc">Spanish</div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 version-name-col order-8">
                        <div className="sup-lang-wrap">

                            <div className="detail-text mobile-flex grid-reg-text">
                                <div className="detail-label">TFN(s)</div>
                                <div className="detail-desc">111-111-111 <br/>
                                    123-111-111
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 grid-date-col order-9">
                        <div className="sup-lang-wrap">

                            <div className="detail-text mobile-flex grid-reg-text">
                                <div className="detail-label">DID(s)</div>
                                <div className="detail-desc">111-111-111 <br/>
                                    123-111-111
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12 order-10 intello-msg">
                        <div className="sup-lang-wrap">

                            <div className="detail-text ">
                                <div className="detail-label">Intello Message</div>
                                <div className="detail-desc">
                                    <p>Thanks you for calling card services, may i please have 16 digits card
                                        number.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
                </>
        )
    });
  return (
        <>
            {redeneredItems}
        </>
  );
}

export default DataRow;

import React, {useState} from "react";
import "./assets/css/style.css";
import Sidebar from "./Components/Sidebar/Sidebar";
import Content from "./Components/Content/Content";
import Landing from "./Components/Landing/Landing";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
// import ModalDisplay from "./Components/ModalDisplay/ModalDisplay";

function App() {
    const [searchOption , setSearchOption] = useState(false);
    const handleFalseSearch = () => {
        setSearchOption(false);
    }
    const handleSearchOption = () => {
        setSearchOption(!searchOption);
    }
    const pathName = window.location.pathname;
  return (
      <>

    <div className={`wrapper ${searchOption === true ? "backdrop-filters" : ""} ${pathName == "/version" ? "version-bg-blur" : pathName == "/ivr" ? "ivr-bg-blur" : pathName == "/user" ? "user-bg-blur" : ""}`}  >
      <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Landing data="LandingPage" />} />
        <Route path="/ivr" element={<Content class='ivr-grid-con' data="VersionManagement" searOptProp={searchOption} closeSearch={handleFalseSearch} searchOptionProp={handleSearchOption}/>} />
        <Route path="/version" element={<Content class='ivr-grid-con version-grid-con' data="IVRManagement" searOptProp={searchOption} closeSearch={handleFalseSearch} searchOptionProp={handleSearchOption} />} />
          <Route path="/user" element={<Content class='ivr-grid-con user-grid-con' data="UserManagement" searOptProp={searchOption} closeSearch={handleFalseSearch} searchOptionProp={handleSearchOption} />} />
          <Route path="/user-roles" element={<Content class='ivr-grid-con user-grid-con' data="UserRoles" searchOptionProp={handleSearchOption} />} />

      </Routes>
      </BrowserRouter>
    </div>
          </>
  );
}

export default App;

import React, { useState } from 'react';
import clockImage from '../../../../assets/images/clock.svg';
import ClientsModal from "../../../ClientsModal/ClientsModal";

const DataRowUser = (props) => {
    const [dropCaret, setDropCaret] = useState(false);

    const handleDropCaret = (e) => {
        setDropCaret(!dropCaret);
    }

    const handleDropCaretDesk = (e) => {

    }
    const screensize = window.innerWidth ;
    let classname , i;
    const onClickClient = () => {
        props.element(props.data.id);
    }
    screensize < 768 ? i = 5 : i=5;
    screensize > 768 && screensize < 1201 ? i = 3 : i =5 ;
    return (
    <>
        <div className="container-fluid">
        <div className="row user_table" >
            <div className="col col-xl-1 col-lg-1  col-md-6 col-6 order-3 order-lg-1 order-md-3 ">
                <div className="mobile-flex">
                <span className="responsiveTitle">User ID</span>
                <span className="grid-reg-text">{props.data.id}</span>
                </div>

            </div>
            <div className="col col-xl-2 col-lg-2 col-md-10 col-sm-10 order-md-1 col-10 order-1 user-grid">
                <div className="user_data">
                    <div className="user_image_wrapper">
                <img src={props.data.profile_img} alt="instance_img_1" />
                    </div>

                <div className="user_details">
                <h1 className="grid-reg-text">{props.data.name}</h1>
                <a href="#" className="grid-reg-email">{props.data.email}</a>
                </div>
                </div>
            </div>
            <div className="col-sm-2 col-2 only-for-mob order-2 more-icon-con">
                <div className="user-actions">
                    <div className="dropdown">
                        <button tabIndex="0" className="icon-more-vertical" id={`versionDropDown1${props.data.id}`} title="icon-more-vertical"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        </button>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby={`versionDropDown1${props.data.id}`}>
                            <a className="dropdown-item option-has-icon" href="javascript:void(0)" data-toggle="modal"
                               data-target="#addUserModal" aria-label="Edit User" onClick={props.elementUser} type="button" ><span className="icon-edit-22">
</span><p>Edit</p></a>
                            <a className="dropdown-item option-has-icon" aria-label="Delete User" href="javascript:void(0)" data-toggle="modal" data-target="#deleteUserModal"  onClick={props.elementDelete}><span className="icon-trash"></span><p>Delete</p></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 order-md-4 order-4 role-type">
                <div className="mobile-flex centered_content">
                <span className="responsiveTitle">Role Type </span>
                <span className={`ivr-grid-status ${ props.data.role.replace(/\s/g, '').toLowerCase()}`} >{props.data.role}</span>
                </div>
            </div>
            <div className="col col-xl-1 col-lg-1  col-md-6 col-sm-6 col-6 order-md-5 order-5 contact-no">
                <div className="mobile-flex">
                <span className="responsiveTitle">Contact Number </span>
                <span className="contact_number grid-reg-text" >{props.data.contactNo}</span>
                </div>
            </div>
            <div className="col col-xl-2 col-lg-2  col-md-6 col-sm-6 order-md-6 col-6 order-6 clients-cust" >
                <div className="mobile-flex">

                <span className="responsiveTitle">Clients </span>
                <div className="clients_icon">
                    <a href="javascript:void(0)" data-toggle="modal"
                       data-target="#clientData" type="button" id={props.data.id} onClick={()=>onClickClient()}  >
                {

                    props.data.clients.slice(0,i).map((data) => (
                        <>
                        <img  src={data.client} alt="Clients" />
                        </>

                ))}
                        {screensize < 768 ?  props.data.clients.length > 5 ? <span> <span class="more-icons"> +{props.data.clients.length - 5} </span>  </span>: "" :  props.data.clients.length > 6 ? <span><span class="more-icons"> +{props.data.clients.length - 6} </span> </span>: ""}

                    </a>

                </div>
                </div>
            </div>
            <div className="col col-md-11 col-lg-2 col-sm-10 col-1 order-md-7 col-10 order-7 last-activity-grid time-with-icon">
                <div className="mobile-flex">

                <span className="responsiveTitle">Last Updated: </span>
                <span className="grid-date-col-icon grid-reg-text" >
                <img src={clockImage} alt="clock" />
                                    <time dateTime="2021-11-04 20:15">{props.data.last_updated}</time>

            </span>
                </div>
            </div>
            <div className="col col-md-1 col-lg-1  col-sm-1 col-1 order-md-8 order-8 mobile-btn actions-grid">
                <div className="actionBtns ">
                    <div className="actionIcons">
                        <a href="javascript:void(0)" data-target="#addUserModal" aria-label="Edit User" data-toggle="modal" onClick={props.elementUser} className="icon-edit-22">

                        </a>
                        <a href="javascript:void(0)" data-toggle="modal" data-target="#deleteUserModal" aria-label="Delete User"  onClick={props.elementDelete} className="icon-trash">
                        </a>

                    </div>

                </div>
            </div>
            <div className="col col-md-1 col-lg-1 col-sm-2 col-2 order-md-8 order-8 status-grid">
                <div className="switchbtn">
                    <div className="actionIcons">

                        <span className="custom-control custom-switch notmodal user-switch">
                            <input aria-label="Switch Element" type="checkbox" className="custom-control-input"
                                   id={"customSwitch" + props.data.id + "1" }  />
                            <label className="custom-control-label" htmlFor={"customSwitch" + props.data.id + "1"}></label>
                        </span>

                    </div>

                </div>
            </div>
        </div>
        </div>

    </>
    );
}

export default DataRowUser;

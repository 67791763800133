import React, {useState} from 'react';

const Onebox = (props) => {
    const [star , activeStar] = useState(false);


  return (
      <>

    <div className="one-box">
        <div className="row">
            <div className="col-xl-9 col-lg-9 col-md-9 col-9 ">
                {props.type === "draft" ?
                    <span className="ivr-grid-status ver-grid-status saved">Draft</span>
                    :
                    <span className="ivr-grid-status ver-grid-status">Saved</span>
                }
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-3  more-icon-con more-icon-hover">

                <div className="dropdown">
                    <button tabIndex="0" className="icon-more-vertical" id={`versionDropDown ${props.heading}`} title="icon-more-vertical"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    </button>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby={`versionDropDown ${props.heading}`}>
                        <a className="dropdown-item option-has-icon" href="javascript:void(0)" title="Rename"><span className="icon-Input"></span><p>Rename</p></a>
                        <a className="dropdown-item option-has-icon" href="javascript:void(0)" title="Duplicate" onClick={props.duplicateMessage}><span className="icon-copy"></span><p>Duplicate</p></a>
                        <a className="dropdown-item option-has-icon" href="javascript:void(0)" title="Delete" data-toggle="modal"
                           data-target="#deleteModal" type="button" onClick={props.element}><span className="icon-trash"></span><p>Delete</p></a>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <a href="#"><h2 className="box-heading">{props.heading}</h2></a>
                <p className="box-text">
                    {props.content}
                </p>
            </div>

        </div>
        <div className="row date-fav-wrapper">
            <div className="col-9">
                <span className="box-last-modified">
                    Last modified
                </span>
                <span className="box-last-modified-date">
                   {props.modifiedOn}
                </span>
            </div>
            <div className="col-3 call-icon">
                <a href="#" title="Call Button">
                <span className="call-icon-con"><span className="icon-call"></span></span>
                <span className="call-type-text">{props.name}</span>
                </a>
            </div>
            {/*<div className="col-2 fav-icon-con">*/}
            {/*    <span tabIndex="0" onClick={() => activeStar(!star)} className={star === true ? "icon-Star_03" : "icon-Star_1"}></span>*/}
            {/*</div>*/}
        </div>
    </div>

    </>
  );
}

export default Onebox;

import React from "react";
import Select, { components } from "react-select";
import { Scrollbar } from "smooth-scrollbar-react";
import "./SmoothScroll.css";

const IVRName = [
    { label: "CBTC", value: "CBTC" },
    { label: "Comerica", value: "Comerica" },
    { label: "Fair Fintech", value: "Fair Fintech" },
    { label: "CBTC", value: "CBTC" },
    { label: "Neteller", value: "Neteller" },
    { label: "CBTC", value: "CBTC" },
    { label: "Comerica", value: "Comerica" },
    { label: "Fair Fintech", value: "Fair Fintech" },
    { label: "CBTC", value: "CBTC" },
    { label: "Neteller", value: "Neteller" },

];
const selectStyle = {
    control: (css , { data, isDisabled, isFocused, isSelected }) => ({ ...css,
        backgroundColor: isFocused ? '#fff' : '#F6F6F6;',
        textAlign: 'left',
        fontSize: '0.833vw',
        color: '#2D2F32',
        left: '0.139vw',
        minHeight: '2.778vw',
        verticalAlign: 'middle',
        width: 'auto',
        boxShadow: 'none',
        borderRadius: '0.417vw',
        borderColor: isFocused ? '#39B7E9' : 'transparent',
        "@media(max-width: 640px)": {
            fontSize: '3.199vw',
            minHeight: '10.668vw',
            left: '0.534vw',
        },
        "@media(max-width: 992px)  and (min-width: 641px)": {
            fontSize: '1.871vw',
            minHeight: '6.241vw',
            left: '0.310vw',
        },
        "&:hover": {

        },
        "&:focus": {
            outline: '0',
            borderColor: '#525252',
        }
    }),
    dropdownIndicator: () => ({
        background: "url('../../assets/images/caret_drop.svg') no-repeat",
        position: 'absolute',
        right: '0.764vw',
        height: '0.833vw',
        width: '0.833vw',
        backgroundSize: 'cover',

        "& svg": {
            display: "none",
        },
        "@media(max-width: 640px)": {
            right: '2.934vw',
            height: '3.199vw',
            width: '3.199vw',
        },
        "@media(max-width: 992px)  and (min-width: 641px)": {
            right: '1.716vw',
            height: '1.871vw',
            width: '1.871vw',
        }
    }),
    option: () => ({
        color: '#2D2F32',
        textAlign: 'left',
        cursor: 'pointer',
        padding: '0.833vw 1.111vw',
        fontSize: '0.903vw',
        "&:hover": {
            backgroundColor: "rgba(249, 249, 249, 1)",
            fontWeight: '500'
        },
        "@media(max-width: 640px)": {
            padding: '3.199vw 4.266vw',
            fontSize: '3.468vw',
        },
        "@media(max-width: 992px)  and (min-width: 641px)": {
            padding: '1.871vw 2.496vw',
            fontSize: '2.029vw',
        }

    }),
    placeholder: () => ({
        color: '#2D2F32',
        fontSize: "0.903vw",
        "@media(max-width: 640px)": {
            fontSize: "3.468vw",

        },
        "@media(max-width: 992px)  and (min-width: 641px)": {
            fontSize: "2.029vw",

        }
        }),
    valueContainer: () => ({
        display: 'flex',
        alignItems: 'center',
        padding: '0.347vw 0.833vw 0',
        "@media(max-width: 640px)": {
            padding: '1.332vw 3.199vw 0',

        },
        "@media(max-width: 992px)  and (min-width: 641px)": {
            padding: '0.779vw 1.871vw 0',

        }
        }),
    singleValue: () => ({
        color: '#000'
    }),
    menu: (base) => ({
        ...base,

        marginLeft: "0.069vw",
        "@media(max-width: 640px)": {
            marginLeft: "0.265vw",

        },
        "@media(max-width: 992px)  and (min-width: 641px)": {
            marginLeft: "0.155vw",

        }
        }),
    menuList: (base) => ({
        ...base,
        border: '0',
        borderRadius: '0.347vw',
        scrollMarginTop: '0',
        paddingTop: '0',
        width: '100%',
        paddingBottom: '0',
        margin: '0.139vw 0 ',
        scrollbarWidth: 'thin',
        scrollbarColor: 'rgba(218, 218, 218, 1) transparent',
        overflow: 'hidden',
        boxShadow: '0px 0.288vw 0.576vw rgba(0, 0, 0, 0.07)',
        "@media(max-width: 640px)": {
            borderRadius: '1.332vw',
            margin: '0.534vw 0 ',

        },
        "@media(max-width: 992px)  and (min-width: 641px)": {
            borderRadius: '0.779vw',
            margin: '0.312vw 0 ',

        },
            "::-webkit-scrollbar": {
            width: "0.278vw",
            borderRadius: "0.694vw",


        },
        "::-webkit-scrollbar-track": {
            background: "transparent",

        },
        "::-webkit-scrollbar-thumb": {
            background: "#DADADA",
            borderRadius: "0.694vw",

        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#cecece"
        }
    })


};
const MenuList = ({ maxHeight, ...props }) => {

    return (
        <components.MenuList {...props}>
            <Scrollbar
                plugins={{
                    overscroll: {
                        effect: "bounce"
                    }
                }}
            >
                <div>
                    <div style={{ maxHeight: 212 }}>{props.children}</div>
                </div>
            </Scrollbar>
        </components.MenuList>
    );
};

export default function VersionPopup() {
    return <Select options={IVRName} title="Version Popup" isSearchable={false} styles={selectStyle} placeholder={"Version Type"}  components={{ MenuList , IndicatorSeparator: () => null }} />;
}

import React, {useState} from 'react';
import VersionPopup from "../SmoothScroll/VersionPopup";

const AddUserModal = () => {
    const screensize = window.innerWidth;
    const [clients , setClients] = useState(false);
    const displayClients = () => {
        setClients(true);
    }
    return (
        <div className={`modal ivr-modal ${screensize > 992 ? "fade" : "fade-mob"}`} id="addUserModal" tabIndex="-1" role="dialog" aria-labelledby="AddUserLabel"
             aria-hidden="true" >
            <div className={`modal-dialog  ${screensize > 992 ? "modal-dialog-centered" : "modal-lg"} `} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title" id="addIVRLabel">Add New User
                            <small>Fill in the form to define a new user in the system.</small>
                        </h2>

                        <div className="modalSwitchWrap">
                            <label className="custom-control-label" htmlFor="modalSwitch">Status</label>
                            <span className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="modalSwitch"/>
                        <label className="custom-control-label" htmlFor="modalSwitch"></label>
                    </span>
                        </div>

                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-row">
                                <div className="col-12 col-md-12">
                                    <div className='upload-file-design'>
                                        <label htmlFor='input-file'>
                                            <span className="upload-plus-circle">+</span>
                                            <span className="upload-content">
                                                <span className="upload-title">Upload User Picture</span>
                                                <span className="upload-description">Allowed file types: png, jpg, jpeg</span>
                                            </span>
                                        </label>
                                        <input id='input-file' type='file'/>
                                    </div>

                                </div>
                                <div className="col-12 col-md-6 forms-field">
                                    <label htmlFor="fullName">Full Name <span
                                        className="mand-field">*</span></label>
                                    <input id="fullName" type="text" placeholder="Jack Sparrow"
                                           className="form-control"/>
                                </div>
                                <div className="col-12 col-md-6 forms-field">
                                    <label htmlFor="email">Email <span className="mand-field">*</span></label>
                                    <input id="email" type="text" placeholder="j.sparrow@i2cinc.com"
                                           className="form-control"/>
                                </div>
                                <div className="col-12 col-md-6 forms-field">
                                    <label htmlFor="userName">User Name <span
                                        className="mand-field">*</span></label>
                                    <input id="userName" type="text" placeholder="Jack Sparrow"
                                           className="form-control"/>
                                </div>
                                <div className="col-12 col-md-6 forms-field">
                                    <label htmlFor="cNum">Contact Number <span className="mand-field">*</span></label>
                                    <input id="cNum" type="text" placeholder="123 456 789"
                                           className="form-control"/>
                                </div>
                                <div className="col-12 col-md-6 forms-field">
                                    <label htmlFor="password">Password <span
                                        className="mand-field">*</span></label>
                                    <input id="password" type="password" placeholder="Enter Password"
                                           className="form-control"/>
                                </div>
                                <div className="col-12 col-md-6 forms-field">
                                    <label htmlFor="cPassword">Confirm Password <span className="mand-field">*</span></label>
                                    <input id="cPassword" type="password" placeholder="Confirm Password"
                                           className="form-control"/>
                                </div>
                                <div className="col-12 col-md-12 forms-field container-role">
                                    <label htmlFor="roleType" className="role-container">Role Type <span className="mand-field">*</span></label>
                                    <div className="roles-list ">
                                    <label htmlFor="superAdmin" className="role-btns">
                                        <input id="superAdmin" type="radio"
                                               className="form-control" name="roles-btn" {...({onClick: () => setClients(false)}) }/>
                                        <span>Super Admin</span>

                                    </label>
                                    <label htmlFor="admin" className="role-btns" onClick={displayClients}>
                                        <input id="admin" type="radio"
                                               className="form-control" name="roles-btn"/>
                                        <span>Admin</span>

                                    </label>
                                    <label htmlFor="customer" className="role-btns" {...({onClick: () => setClients(false)}) } >
                                        <input id="customer" type="radio"
                                               className="form-control" name="roles-btn"/>
                                        <span>Customer</span>

                                    </label>
                                    </div>

                                </div>
                                <div className={`col-12 col-md-12 forms-field container-role clients-btns ${clients  ? 'd-block' : 'd-none'}`}>
                                    <label htmlFor="roleType" className="role-container">Clients <span className="mand-field">*</span></label>
                                    <div className="roles-list client-role-list">
                                        <label htmlFor="client1" className="role-btns">
                                            <input id="client1" type="checkbox"
                                                   className="form-control" />
                                            <span>Client1</span>

                                        </label>
                                        <label htmlFor="client2" className="role-btns" onClick={displayClients}>
                                            <input id="client2" type="checkbox"
                                                   className="form-control" />
                                            <span>Client2</span>

                                        </label>
                                        <label htmlFor="client3" className="role-btns">
                                            <input id="client3" type="checkbox"
                                                   className="form-control" />
                                            <span>Client3</span>

                                        </label>
                                    </div>

                                </div>
                            </div>




                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-primary">Proceed</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddUserModal;

import React, {useState, useEffect} from 'react'
import TableHeaderUser from "./TableHeader/TableHeaderUser";
import DataRowUser from "./DataRow/DataRowUser";
import InstanceImage1 from '../../../assets/images/icons/instance_img_1.png';
import InstanceImage2 from '../../../assets/images/icons/instance_img_2.png';
import InstanceImage3 from '../../../assets/images/icons/instance_img_3.png';
import user1 from '../../../assets/images/icons/user1.png';
import user2 from '../../../assets/images/icons/user2.png';
import user3 from '../../../assets/images/icons/user3.png';
import user4 from '../../../assets/images/icons/user4.png';
import user5 from '../../../assets/images/icons/user5.png';
import cibc from '../../../assets/images/icons/cibc.png';
import client1 from '../../../assets/images/icons/Greenphire.png';
import client2 from '../../../assets/images/icons/pp.png';
import RolesManagement from "./RolesManagement";
import ClientsModal from "../../ClientsModal/ClientsModal";
import AddUserModal from "../../AddUserModal/AddUserModal";
import DeleteModal from "../../DeleteModal/DeleteModal";
import DeleteUserModal from "../../DeleteModal/DeleteUserModal";
import DataRow from "../VersionManagement/DataRow.js/DataRow";

const UserManagement = (props) => {

    const [getData, setGetData] = useState([]);
    const [openClientData , setOpenClientData] = useState(false);
    const handleOpenClientData = (id) => {
        setOpenClientData(true);
    }
    const [openUserModal , setOpenUserModal] = useState(false);
    const handleOpenUser = () => {
        setOpenUserModal(true);
    }
    const [openDeleteModal , setOpenDeleteModal] = useState(false);
    const handleOpenDeleteModal = () => {
        setOpenDeleteModal(true);
    }
    const [openMessage , setOpenMessage] = useState(false);
    const handleOpenMessage = () => {
        setOpenMessage(!openMessage);
        setTimeout(()=> {
            setOpenMessage(false);
        }, 5000);
    }
    useEffect(() => {
        setGetData([
            {
                id: 121541,
                name: "Jack Sparrow",
                email: 'jack.sparrow@i2cinc.com',
                profile_img: user1,
                contactNo: '+1 650.593.5400',
                clients: [
                    {
                        clientName: "cibc",
                        client: cibc,

                    },
                    {
                        clientName: "alverda",
                        client: client1,
                    },
                    {
                        clientName: "cibc",
                        client: client2,
                    },
                    {
                        clientName: "cibc",
                        client: cibc,

                    },
                    {
                        clientName: "cibc",
                        client: client1,
                    },
                    {
                        clientName: "cibc",
                        client: client2,
                    },
                    {
                        clientName: "cibc",
                        client: cibc,

                    },
                    {
                        clientName: "cibc",
                        client: client1,
                    },
                    {
                        clientName: "cibc",
                        client: client2,
                    },
                ],
                version: "Balance & CSV IVR 4",
                last_updated: "Nov 4 2021, 10:15 PM",
                role: "Super Admin",
                stateSwitch: true,
            },
            {
                id: 231451,
                name: "Aeldra Versioning",
                email: 'jack.sparrow@i2cinc.com',
                profile_img: user2,
                contactNo: '+1 650.593.5400',
                clients: [
                    {
                        clientName: "cibc",
                        client: cibc,

                    },
                    {
                        clientName: "cibc",
                        client: client1,
                    },
                    {
                        clientName: "cibc",
                        client: client2,
                    },
                ],
                version: "IVRU Version",
                last_updated: "Nov 4 2021, 10:15 PM",
                role: "Admin",
                stateSwitch: false,
            },
            {
                id: 2124584,
                name: "Fair Versioning",
                email: 'jack.sparrow@i2cinc.com',
                profile_img: user3,
                contactNo: '+1 650.593.5400',
                clients: [
                    {
                        clientName: "cibc",
                        client: cibc,

                    },

                ],
                version: "CSV IVR With Language Selection",
                last_updated: "Nov 4 2021, 10:15 PM",
                role: "Customer",
                stateSwitch: true,
            },
            {
                id: 2154584,
                name: "CMA Versioning",
                email: 'jack.sparrow@i2cinc.com',
                profile_img: user4,
                contactNo: '+1 650.593.5400',
                clients: [
                    {
                        clientName: "cibc",
                        client: cibc,

                    },
                    {
                        clientName: "cibc",
                        client: client1,

                    },
                    {
                        clientName: "cibc",
                        client: client2,

                    },
                    {
                        clientName: "cibc",
                        client: cibc,

                    },
                    {
                        clientName: "cibc",
                        client: client1,

                    },

                ],
                version: "Balance & CSV IVR 4",
                last_updated: "Nov 4 2021, 10:15 PM",
                role: "Admin",
                stateSwitch: true,
            },
            {
                id: 514541,
                name: "Aeldra Versioning",
                email: 'jack.sparrow@i2cinc.com',
                profile_img: user5,
                contactNo: '+1 650.593.5400',
                clients: [
                    {
                        clientName: "cibc",
                        client: cibc,

                    },
                    {
                        clientName: "cibc",
                        client: client1,
                    },
                    {
                        clientName: "cibc",
                        client: client2,
                    },
                ],
                version: "IVRU Version",
                last_updated: "Nov 4 2021, 10:15 PM",
                role: "Customer",
                stateSwitch: false,
            },
            {
                id: 525145,
                name: "Aeldra Versioning",
                email: 'jack.sparrow@i2cinc.com',
                profile_img: user1,
                contactNo: '+1 650.593.5400',
                clients: [
                    {
                        clientName: "cibc",
                        client: cibc,

                    },


                ],
                version: "IVRU Version",
                last_updated: "Nov 4 2021, 10:15 PM",
                role: "Super Admin",
                stateSwitch: false,
            },{
                id: 5545132,
                name: "Aeldra Versioning",
                email: 'jack.sparrow@i2cinc.com',
                profile_img: user4,
                contactNo: '+1 650.593.5400',
                clients: [
                    {
                        clientName: "cibc",
                        client: cibc,

                    },
                    {
                        clientName: "cibc",
                        client: client1,
                    },
                    {
                        clientName: "cibc",
                        client: client2,
                    },
                    {
                        clientName: "cibc",
                        client: cibc,

                    },
                    {
                        clientName: "cibc",
                        client: client1,
                    },
                    {
                        clientName: "cibc",
                        client: client2,
                    }, {
                        clientName: "cibc",
                        client: cibc,

                    },
                    {
                        clientName: "cibc",
                        client: client1,
                    },
                    {
                        clientName: "cibc",
                        client: client2,
                    },
                ],
                version: "IVRU Version",
                last_updated: "Nov 4 2021, 10:15 PM",
                role: "Admin",
                stateSwitch: false,
            },{
                id: 5545121,
                name: "Aeldra Versioning",
                email: 'jack.sparrow@i2cinc.com',
                profile_img: user3,
                contactNo: '+1 650.593.5400',
                clients: [
                    {
                        clientName: "cibc",
                        client: cibc,

                    },
                    {
                        clientName: "cibc",
                        client: client1,
                    },
                    {
                        clientName: "cibc",
                        client: client2,
                    },
                ],
                version: "IVRU Version",
                last_updated: "Nov 4 2021, 10:15 PM",
                role: "Customer",
                stateSwitch: false,
            },{
                id: 55645121,
                name: "Aeldra Versioning",
                email: 'jack.sparrow@i2cinc.com',
                profile_img: user2,
                contactNo: '+1 650.593.5400',
                clients: [
                    {
                        clientName: "cibc",
                        client: cibc,

                    },

                ],
                version: "IVRU Version",
                last_updated: "Nov 4 2021, 10:15 PM",
                role: "Admin",
                stateSwitch: false,
            },


        ])
    },[]);
    const [searchData , setSearchData] = useState([]);
    const handleSearch = (event , id , allData) => {

        let searchQuery = event.target.value.toLowerCase();
        let displayedData = allData.filter(function(el) {
            let searchValue = el.name.toLowerCase();
            return searchValue.indexOf(searchQuery) !== -1;
        });
        if(event.target.value == ""){
            displayedData = [];
        }
        setSearchData(displayedData);
    }
    return(
        <>
            <div className="user_container bordered-table">
                
                <div className="user_body">
                    <div className="m-0">

                        <TableHeaderUser />
            <div className="user_management">
            {getData.map((data, index) => (
                <>
                    {
                        index == 1 ?
                            <div className={`search-container-main ${props.searOptsProp ? 'd-block' : 'd-none'}`}>
                                <div className="search-cont search-show"><input type="text" onChange={event => handleSearch(event,  data.id , getData)} className="form-control search-bar" placeholder="Search by name" />
                                    <span className="search-icon"></span>
                                    <span className={`toggle-filter`} onClick={() => props.closeSearchProp()} ></span>
                                </div>

                            </div>
                            :
                            ""
                    }

                <DataRowUser data={data} element={ handleOpenClientData} elementUser={handleOpenUser} elementDelete={handleOpenDeleteModal} />
                </>
            ))}
            </div>
                        <div className={`search-body ${props.searOptsProp ? "show" : ""}`}>
                            {
                                searchData.length > 0 ?
                                    searchData.map((searchedData) => (
                                        <div className="user_management">
                                            <DataRowUser data={searchedData} element={ handleOpenClientData} elementUser={handleOpenUser} elementDelete={handleOpenDeleteModal} />
                                        </div>
                                    ))
                                    : ""
                            }
                        </div>
            </div>
                    <div className={`message-alert ${openMessage === true ? "show-message" : ""}`}>
                        <span className="icon-check-circle"></span>
                        <span className="message-text">User account has been deleted successfully.</span>
                        <a href="javascript:void(0)" { ...( { onClick: () => setOpenMessage(false) } ) } className="icon-Union1"></a>
                    </div>
                </div>

            </div>

            {
                openClientData ? <ClientsModal data={getData}  /> : ""
            }
            {
                openUserModal ? <AddUserModal /> : ''
            }
            {
                openDeleteModal ? <DeleteUserModal element={handleOpenMessage} /> : ''
            }
        </>
    )
}
export default UserManagement;


import React from "react";
import Select, { components } from "react-select";
import { Scrollbar } from "smooth-scrollbar-react";
import "./SmoothScroll.css";

const versionTypes = [
    { label: "11:00", value: "11:00" },
    { label: "11:30", value: "11:30" },
    { label: "12:00", value: "12:00" },
    { label: "12:30", value: "12:30" },

];
const selectStyle = {
    container: (css) => ({
        ...css,
        "&:focus": {
            backgroundColor: '#fff',
            borderColor: 'red',
        }
    }),
    control: (css , { data, isDisabled, isFocused, isSelected }) => ({ ...css,
        backgroundColor: isFocused ? '#fff' : '#F6F6F6;',
        textAlign: 'left',
        fontSize: '0.833vw',
        color: '#2D2F32',
        left: '0.139vw',
        minHeight: '2.778vw',
        verticalAlign: 'middle',
        cursor: "pointer",
        width: '100%',
        borderRadius: '0.417vw',
        boxShadow: '0px 0.278vw 0.556vw rgba(0, 0, 0, 0.01)',
        border: isFocused ? '0.069vw solid #39B7E9' : 'none',
        "&:hover": {

        },
        "&:focus": {
            outline: '0',
            borderColor: '#525252',
            backgroundColor: '#fff',
        },
        "@media(max-width: 640px)": {
            fontSize: '3.199vw',
            left: '0.534vw',
            minHeight: '10.668vw',
            borderRadius: '1.601vw',

        },
        "@media(max-width: 992px)  and (min-width: 641px)": {
        fontSize: '2.029vw',
            minHeight: '6.241vw',
            left: '0.534vw',

        },
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
        ...styles,
        color: '#2D2F32',
        textAlign: 'left',
        cursor: 'pointer',
        backgroundColor: isSelected ? "rgb(224,224,224)" : '#fff',
        padding: '0.833vw 1.111vw',

        fontSize: '0.833vw',
        width: '100%',
        "&:hover": {
            backgroundColor: "rgba(249, 249, 249, 1)",
        },
        "@media(max-width: 640px)": {
            padding: '3.199vw 4.266vw',
            fontSize: '3.199vw',
        },
        "@media(max-width: 992px)  and (min-width: 641px)": {
            padding: '1.871vw 2.496vw',
            fontSize: '1.871vw',
        }

    }),
    placeholder: () => ({
        color: 'black',
        paddingTop: '0.069vw',
        "&:focus": {
            backgroundColor: '#fff',
            borderColor: 'red',
        },
        "@media(max-width: 640px)": {
            paddingTop: '0.265vw',

        },
        "@media(max-width: 992px)  and (min-width: 641px)": {
            paddingTop: '0.155vw',

        }
        }),
    valueContainer: () => ({
        display: 'flex',
        alignItems: 'center',
        padding: '0.069vw 0.903vw 0',
        "@media(max-width: 640px)": {
            padding: '0.265vw 3.468vw 0',

        },
        "@media(max-width: 992px)  and (min-width: 641px)": {
            padding: '0.155vw 2.029vw 0',
        }
        }),
    singleValue: () => ({
        color: '#000'
    }),
    dropdownIndicator: () => ({
        background: "url('../../assets/images/clock.svg') no-repeat",
        position: 'absolute',
        right: '1.111vw',
        height: '1.111vw',
        width: '1.111vw',
        backgroundSize: 'cover',

        "& svg": {
            display: "none",
        },
        "@media(max-width: 640px)": {
            right: '4.266vw',
            height: '4.266vw',
            width: '4.266vw',
        },
        "@media(max-width: 992px)  and (min-width: 641px)": {
            right: '2.496vw',
            height: '2.496vw',
            width: '2.496vw',
        }
        }),
    menu: (base) => ({
        ...base,

        marginLeft: "0.069vw",
        "@media(max-width: 992px)": {
            marginLeft: "0.265vw",

        }
        }),
    menuList: (base) => ({
        ...base,

        border: '0',
        outline: '0',
        borderRadius: '0.347vw',
        scrollMarginTop: '0px',
        paddingTop: '0',
        width: '100%',
        paddingBottom: '0',
        overflow: 'hidden',
        margin: '0.139vw 0.139vw 0',
        boxShadow: '0px 0.288vw 0.576vw rgba(0, 0, 0, 0.07)',
        "@media(max-width: 992px)": {
            borderRadius: '1.332vw',
            margin: '0.534vw 0.534vw 0',
            boxShadow: '0px 1.106vw 2.212vw rgba(0, 0, 0, 0.07)',

        }
    })


};
const MenuList = ({ maxHeight, ...props }) => {

    return (
        <components.MenuList {...props}>
            <Scrollbar
                plugins={{
                    overscroll: {
                        effect: "bounce"
                    }
                }}
            >
                <div>
                    <div style={{ maxHeight: 212 }}>{props.children}</div>
                </div>
            </Scrollbar>
        </components.MenuList>
    );
};

export default function TimeList(props) {
    return <Select options={versionTypes} title="Time List" isSearchable={false} styles={selectStyle} placeholder={"11:30"}   components={{ MenuList , IndicatorSeparator: () => null }} />;
}

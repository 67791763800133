import React, {useState} from 'react';
import i2cLogo from '../../assets/images/logo.svg';
import Avatar from '../../assets/images/icons/av-mob.png';
import Notification_Icon from '../../assets/images/notification.png';

const HeaderBar = (props) => {
    let notification= [
        {
            type: 'meeting',
            title: 'CS IVR Scheduled',
            message: 'CS IVR Version has been scheduled for',
            time: '50s ago',
            status: 'new',
            date: 'Oct 17, 2021 11:38:00',
            icon: 'icon-Notification'

        },
        {
            type: 'user',
            title: 'New User Added',
            message: 'New User has been added in the system as “Admin”',
            time: '2 months ago',
            status: '',
            date: '',
            icon: 'icon-user-icon'
        }
        ,
        {
            type: 'user',
            title: 'CS IVR Scheduled',
            message: 'CS IVR Version has been scheduled for',
            time: '50s ago',
            status: '',
            date: 'Oct 17, 2021 11:38:00',
            icon: 'icon-calender'
        }
        ,
        {
            type: 'user',
            title: 'New User Added',
            message: 'New User has been added in the system as “Admin”',
            time: '1 hr ago',
            status: '',
            date: '',
            icon: 'icon-plus-with-circle-icon'
        } ,
        {
            type: 'user',
            title: 'CS IVR Scheduled',
            message: 'CS IVR Version has been scheduled for',
            time: '50s ago',
            status: '',
            date: 'Oct 17, 2021 11:38:00',
            icon: 'icon-calender'
        }
    ];
    const screensize = window.innerWidth;
    const [openShadow , setOpenShadow] = useState(false);
    const handleClick = () => {
        setOpenShadow(!openShadow);
    }
    let count=0, findValue ;
    notification.map((data) => {
        if(data.status == "new"){
            return count++;
        }

    })

    return (

        <header>
                        <div className="avatarWrapper">
                            <div  className={`${props.element3 === true ? "show-notification" : ''}`}>
                                <div className={`dropdown notification-dropdown`}>
                                    <button tabIndex="0" className={`btn avatar-name ${count > 0 ? "new-notification" : ""} ${props.element3 === true ? "avatar-margin" : ""} `} id="notificationDropdown" title="icon-more-vertical"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                                            <span className="header-notification " >
                                                <span className="icon-notification-bing"><span className="path1"></span><span
                                                    className="path2"></span><span className="path3"></span></span>
                                            </span>
                                        <span className={`${props.element3 === true ? "opacity-shown" : "opacity-hidden"} cust-padding nav-text`}>Notification</span>

                                    </button>

                                    <div className="dropdown-menu dropdown-menu-right text-muted notification-container" aria-labelledby="notificationDropdown">
                                        <div className="flex-cust">
                                        {
                                            screensize < 993 ?  <span className="close-icon icon-Union1" ></span> : ''
                                        }
                                        <a href="/" className="i2c-logo" >
                                            <img src={i2cLogo} alt="i2c Logo" />
                                        </a>
                                            <div className="hide-element">
                                        <div className={`${props.element3 === true ? "show-notification" : ''}`} >
                                            <div  className={`dropdown avatar-dropdown `}>
                                                <button className="avatar-dropdown-toggle" type="button" id="dropdownMenuButton"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  { ...( screensize < 993  && {onClick: () => setOpenShadow(!openShadow)}) }>
                                                    <img src={Avatar} alt="User Avatar" />
                                                </button>
                                                <div className={`avatar-details avatar-sidebar ${props.element3 === true ? "show-avatar-sidebar" : ""}`}>
                                                    <h1>William Smith</h1>
                                                    <a href="#" >williamsmith@i2cinc.com</a>
                                                </div>
                                                <div className="dropdown-menu fade-mob dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                    <div className="avatar-area">
                                                        <div className="avatar-img" >
                                                            <img src={Avatar} alt="User Avatar" />

                                                        </div>
                                                        <div className="avatar-details">
                                                            <h1>William Smith</h1>
                                                            <a href="#" >williamsmith@i2cinc.com</a>
                                                        </div>
                                                    </div>
                                                    <hr className="dropdown-divider" />
                                                    <div className="dropdown-items">
                                                        <a className="dropdown-item" href="javascript:void(0)">{
                                                            <span className="icon icon-user"></span> }<span>Profile</span></a>

                                                        <a className="dropdown-item" href="/">{
                                                            <span className="icon icon-logout"></span>}<span>Sign out</span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                            </div>
                                        </div>
                                        <div className="notification-header">
                                            <h1>Notifications</h1>
                                            <span>2 New</span>
                                        </div>
                                        <div className="notification-body">
                                            {
                                                notification.map((data)=> (
                                                    <div className={`notification-box ${data.status}`}>
                                                        <div className={`img-wrapper ${data.status}`}>
                                                            <span className={`${data.icon}`} ></span>
                                                        </div>
                                                        <a href="#" className="notification-content">
                                                            <div className="content-header">
                                                                <h2>{data.title}</h2>
                                                                <span className="notification-time">{data.time}</span>
                                                            </div>
                                                            <p>{data.message} <b> {data.date}</b></p>

                                                        </a>
                                                    </div>
                                                ))

                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <a href="/" className="i2c-logo" >
                                <img src={i2cLogo} alt="i2c Logo" />
                            </a>

                            <div className={`${props.element3 === true ? "show-notification" : ''}`} >
                                <div  className={`dropdown avatar-dropdown `}>
                                    <button className="avatar-dropdown-toggle" type="button" id="dropdownMenuButton"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  { ...( screensize < 993  && {onClick: () => setOpenShadow(!openShadow)}) }>
                                        <img src={Avatar} alt="User Avatar" />
                                        <div className={`avatar-details avatar-sidebar ${props.element3 === true ? "show-avatar-sidebar" : ""}`}>
                                            <h1>William Smith</h1>
                                            <a href="#" >williamsmith@i2cinc.com</a>
                                        </div>
                                    </button>

                                    <div className="dropdown-menu fade-mob dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                        <div className="avatar-area">
                                            <div className="avatar-img" >
                                                <img src={Avatar} alt="User Avatar" />

                                            </div>
                                            <div className="avatar-details">
                                                <h1>William Smith</h1>
                                                <a href="#" >williamsmith@i2cinc.com</a>
                                            </div>
                                        </div>
                                        <hr className="dropdown-divider" />
                                        <div className="dropdown-items">
                                            <a className="dropdown-item" href="javascript:void(0)">{
                                                <span className="icon icon-user"></span> }<span>Profile</span></a>

                                            <a className="dropdown-item" href="/">{
                                                <span className="icon icon-logout"></span>}<span>Sign out</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

        </header>
    )
}

export default HeaderBar;

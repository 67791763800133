import React from 'react';
import './ModalDisplay.css';
import Select, {  StylesConfig} from 'react-select';
import VersionPopup from "../SmoothScroll/VersionPopup";
const ModalDisplay = () => {
const screensize = window.innerWidth;
  return (
      <>
<div className={`modal ivr-modal ${screensize > 992 ? "fade" : "fade-mob"}`} id="addIVR" tabindex="-1" role="dialog" aria-labelledby="addIVRLabel" aria-hidden="true" >
    <div className={`modal-dialog  ${screensize > 992 ? "modal-dialog-centered" : "modal-lg"} `} role="document">
        <div className="modal-content">
            <div className="modal-header">
                <h2 className="modal-title" id="addIVRLabel">Add New IVR
                    <small>Fill in the form to define a new IVR in the system.</small>
                </h2>



            </div>
            <div className="modal-body custom-position">
                <form>
                    <div className="form-row">
                        <div className="col-12 col-md-6 dropDownField forms-field">
                            <label htmlFor="IVR-Name-Drop">IVR Name <span className="mand-field">*</span></label>
                            <input id="IVR-Name-Drop" type="text" placeholder="Version Name" className="form-control" />
                        </div>
                        <div className="col-12 col-md-6 dropDownField forms-field">
                            <label htmlFor="client-Drop">Client <span className="mand-field">*</span></label>
                          <VersionPopup />
                        </div>
                        <div className="col-12 col-md-6 dropDownField forms-field">
                            <label htmlFor="version-type-Drop">Version Type <span className="mand-field">*</span></label>
                            <VersionPopup />

                        </div>
                        <div className="col-12 col-md-6 dropDownField forms-field">
                            <label htmlFor="sup-lang-Drop">Supported Language <span className="mand-field">*</span></label>
                            <VersionPopup />

                        </div>
                        <div className="col-12 col-md-6 dropDownField forms-field">
                            <label htmlFor="default-lang-Drop">Default Language <span className="mand-field">*</span></label>
                            <VersionPopup />

                        </div>
                        <div className="col-12 col-md-6 dropDownField forms-field">
                            <label htmlFor="ivr-numbers-Drop">IVR Numbers(s) <span className="mand-field">*</span></label>
                            <VersionPopup />

                        </div>
                        <div className="col-12 col-md-6 numbersField forms-field">
                            <label htmlFor="tfn-field">TFN(s) <span className="mand-field">*</span></label>
                            <input id="tfn-field" type="text" placeholder="Numbers" className="form-control" />
                            <span className="plus-icon">
                                <a href="#" ><img src="../../assets/images/plus-circle-grey.svg" /></a>
                            </span>
                        </div>
                        <div className="col-12 col-md-6 numbersField forms-field">
                            <label htmlFor="did-field">DID(s) <span className="mand-field">*</span></label>
                            <input id="did-field" type="text" placeholder="Numbers" className="form-control" />
                            <span className="plus-icon">
                                <a href="#" ><img src="../../assets/images/plus-circle-grey.svg" /></a>
                            </span>
                        </div>
                        <div className="col-12 textAreaField forms-field">
                            <label htmlFor="greetings-field">Intello Greetings Message <span className="mand-field">*</span></label>
                            <textarea id="greetings-field" placeholder="Enter Message" className="form-control"></textarea>
                        </div>
                    </div>



                </form>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-primary">Proceed</button>
            </div>
        </div>
    </div>
</div>


      </>
  )
}

export default ModalDisplay;

import React, {useState} from 'react';
import iconClose from '../../assets/images/icons/icon_close.png';
import Select from 'react-select';
import VersionType from "../SmoothScroll/SmoothScroll";
import IvrList from "../SmoothScroll/IvrList";

const FilterModal = (props) => {
    const screensize = window.innerWidth;

    const [showVersionName , setShowVersionName] = useState(true);
    const [showVersionType, setShowVersionType] = useState(false);

    const handleShowVersionName = () => {
        setShowVersionName(true);
        setShowVersionType(false);

    }
    const handleShowVersionType = () => {
        setShowVersionType(true);
        setShowVersionName(false);

    }
  return (
    <div className={`modal ivr-modal filter-modal ${screensize > 992 ? "fade" : "fade-mob"}`} id="filterModal" tabIndex="-1" role="dialog" aria-labelledby="FilterModal"
         aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">

                <div className="modal-body pageFilters">
                    <form>
                        <div className="row">
                            <div className="col-12 ">
                                <ul className="list-tabs">
                                    <li className={showVersionName === true ? 'active' : ''} onClick={handleShowVersionName}>Version Name</li>
                                    <li className={showVersionType === true ? 'active' : ''} onClick={handleShowVersionType}>Version Type</li>
                                </ul>
                               <ul className={`version-list  ${showVersionName === false ? 'd-none' : showVersionType === false ? 'd-block' : ''}`}>
                                   <li><a href="#">Standard Version</a></li>
                                   <li><a href="#">CSR Version</a></li>
                                   <li className="active"><a href="#">Standard Version</a></li>
                                   <li><a href="#">CSR Version</a></li>
                                   <li><a href="#">Standard Version</a></li>
                               </ul>
                                <ul className={`version-list d-none ${showVersionType === true ? 'd-block' : showVersionName === false ? 'd-none' : ''}`}>
                                    <li><a href="#">Standard Version</a></li>
                                    <li><a href="#">CSR Version</a></li>

                                </ul>
                            </div>


                        </div>
                    </form>
                </div>
                <div className="modal-footer justify-content-center">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" >Cancel</button>
                    <button type="button" className="btn btn-primary">Apply</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default FilterModal;

import React, {useState} from 'react';
import '../../assets/css/style.css';
import {Link, useLocation} from "react-router-dom";
import i2cLogo from "../../assets/images/logo.svg";
import HeaderBar from "../HeaderBar/HeaderBar";

const Sidebar = (props) => {

    const location = useLocation();
  const screensize = window.innerWidth ;
const testFun = () => {
}
  return  (
      <>

    <nav id="sidebar" { ...( screensize > 992  && {onMouseEnter: () => props.sidebarBtn()} ) }   { ...( screensize > 992  && {onMouseLeave: () => props.setSideBarBtn()} ) }  className={screensize < 993 ? "mobile-menu" : props.element === true ? "" : "collapsed"}>
      <div className="sidebar-header">

        <div className="sidebarLogoWrapper">

          <a href="/" className="logo-link">
            <img src={i2cLogo} alt="i2c Logo" />
            <span className="logo-text">IVR</span>
          </a>
        </div>

      </div>
      <div className="sidebar-nav">
      <ul className="list-unstyled components">
        <li className={location.pathname === "/version" ? 'active' : ''} >
        <Link to="/version" className="side-menu-link" title="Version Management" >
          {/* <a
            aria-label="Menu Item"
            className="side-menu-link"
            href="javascript:void(0)"
          > */}
          <span className="icon side-bar-icon icon-direct"><span className="path1"></span><span className="path2"></span><span
              className="path3"></span><span className="path4"></span></span>

          <span className="nav-text">Version { screensize < 993 ? "" : "Management" }</span>
          {/* </a> */}
          </Link>
        </li>
        <li className={location.pathname === "/ivr" ? 'active' : ''} >
        <Link to="/ivr" className="side-menu-link" title="IVR Management" >
          {/* <a
            aria-label="Menu Item"
            className="side-menu-link"
            href="#homeSubmenu"
          > */}
          <span className="icon side-bar-icon icon-hierarchy-square"><span className="path1"></span><span className="path2"></span></span>
          <span className="nav-text">IVR { screensize < 993 ? "" : "Management" }</span>
          {/* </a> */}
          </Link>
        </li>
        <li className={location.pathname === "/user" ? 'active users' : 'users'}>
          <Link
            title="User Management"
            className="side-menu-link"
            to="/user"
          >
            <span className="icon icon-user-minus"><span className="path1"></span><span className="path2"></span><span
                className="path3"></span></span>          <span className="nav-text"> { screensize < 993 ? "User" : "User Management" }</span>
          </Link>
        </li>
        <li className={location.pathname === "/user-roles" ? 'active roles' : 'roles'}>
          <Link
              title="User Roles"
              className="side-menu-link"
              to="/user-roles"
          >
            <span className="icon icon-profile-2user"><span className="path1"></span><span className="path2"></span><span
                className="path3"></span><span className="path4"></span></span>       <span className="nav-text"> { screensize < 993 ? "Roles" : "User Roles" }</span>
          </Link>
        </li>
        <li>
          <a
            title="FAQ"
            className="side-menu-link"
            href="javascript:void(0)"
          >
            <span className="icon icon-Group-35481"><span className="path1"></span><span className="path2"></span></span>           <span className="nav-text">FAQs</span>
          </a>
        </li>
      </ul>
        <ul className="sidebar-header-bar list-unstyled components">
          <HeaderBar element3={props.element} />
        </ul>
      </div>
    </nav>

      </>
  );
}

export default Sidebar;

import React, { useState } from 'react';
import clockImage from '../../../../assets/images/clock.svg';

const DataRowRoles = (props) => {
    const [dropCaret, setDropCaret] = useState(false);

    const handleDropCaret = (e) => {
        setDropCaret(!dropCaret);
    }

    const handleDropCaretDesk = (e) => {

    }
    const screensize = window.innerWidth ;
    let i;
    screensize < 992 ? i = 10 : i = 6;
    return (
        <div className="container-fluid">

        <div className="row user_table roles_table" onClick={window.innerWidth < 767 ? handleDropCaret : handleDropCaretDesk}>
            <div className="col col-xl-1 col-lg-1  col-md-10 order-1 col-sm-10 col-10 roles-type-grid order-sm-1">
                <span className={`ivr-grid-status ${ props.data.role_type.replace(/\s/g, '').toLowerCase()}`}>{props.data.role_type}</span>

            </div>

            <div className="col-2 col-sm-2 only-for-mob order-2 more-icon-con order-sm-2">
                <div className="user-actions more-icon-con">
                    <div className="dropdown">
                        <button tabIndex="0" className="icon-more-vertical" id={`versionDropDown1${props.data.id}`} title="icon-more-vertical"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        </button>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby={`versionDropDown1${props.data.id}`}>
                            <a className="dropdown-item option-has-icon" href="javascript:void(0)" data-toggle="modal"
                               data-target="#rolesModal" type="button" aria-label="Edit Role" onClick={props.element}><span className="icon-edit-22">
</span>Edit</a>
                            <a className="dropdown-item option-has-icon" href="javascript:void(0)" data-toggle="modal"
                               data-target="#deleteRoleModal" aria-label="Delete Role" type="button" onClick={props.deleteModal}><span className="icon-trash"></span>Delete</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col col-xl-7 col-lg-7  col-md-12 col-12 col-sm-12 order-3 permissions-grid order-sm-3" >
                <div className="mobile-flex">

                <span className="responsiveTitle">Permissions </span>
                <div className="custom-margin">
                    {

                        props.data.permissions.slice(0,i).map((data) => (
                            <span className="permissions_box">{data.permission}</span>

                        ))}
                    {screensize < 992 ?  props.data.permissions.length > 10 ? <span class="more-roles"> +{props.data.permissions.length - 10} </span> : "" :  props.data.permissions.length > 6 ? <span class="more-roles"> +{props.data.permissions.length - 6} </span> : ""}

                </div>
                </div>
            </div>
            <div className="col col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 order-4 last-activity-grid order-sm-4 time-with-icon">
                <div className="mobile-flex">

                <span className="responsiveTitle">Last Updated: </span>
                <span className="grid-date-col-icon grid-reg-text">
                <img src={clockImage} alt="clock" />
                                    <time dateTime="2021-11-04 20:15">{props.data.last_updated}</time>

            </span>
                </div>
            </div>
            <div className="col-md-2 col-lg-1 col-sm-2 col-1 order-sm-5 actions-grid mobile-btn">
                <div className="actionBtns">
                    <div className="actionIcons">
                        <a href="javascript:void(0)" type="button" data-toggle="modal" aria-label="Edit Role" data-target="#rolesModal" className="icon-edit-22" onClick={props.element}>
                        </a>
                        <a href="javascript:void(0)" data-toggle="modal"
                           data-target="#deleteRoleModal" type="button" aria-label="Delete Role" onClick={props.deleteModal} className="icon-trash">
                        </a>

                    </div>

                </div>
            </div>

        </div>
        </div>
    );
}

export default DataRowRoles;

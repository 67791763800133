import React, {useState, useEffect} from 'react'
import TableHeaderRoles from "./TableHeader/TableHeaderRoles";
import DataRowRoles from "./DataRow/DataRowRoles";
import RolesModal from "../../RolesModal/RolesModal";
import DeleteModal from "../../DeleteModal/DeleteModal";
import DeleteRolesModal from "../../DeleteModal/DeleteRolesModal";

const RolesManagement = () => {

    const [getData, setGetData] = useState([]);
    const [openRolesModal , setOpenRolesModal] = useState(false);
    const handleOpenRolesModal = () => {
        setOpenRolesModal(true);
    }
    useEffect(() => {
        setGetData([
            {
                id: '1',
                role_type: 'Super Admin',
                permissions: [
                    {
                        permission: 'View IVR',

                    },
                    {
                        permission: 'Edit IVR',
                    },
                    {
                        permission: 'Add IVR',
                    },
                    {
                        permission: 'View Versions',
                    },
                    {
                        permission: 'Edit Versions',
                    },
                    {
                        permission: 'Add Versions',
                    },
                    {
                        permission: 'View Versions',
                    },
                    {
                        permission: 'Edit Users',
                    },
                    {
                        permission: 'Add Users',
                    },
                    {
                        permission: 'Delete Users',
                    },
                    {
                        permission: 'Update Users',
                    },
                    {
                        permission: 'Select Users',
                    },
                ],

                last_updated: "Nov 4 2021, 10:15 PM",

            },
            {
                id: '2',
                role_type: 'Admin',
                permissions: [
                    {
                        permission: 'View IVR',

                    },
                    {
                        permission: 'Edit IVR',
                    },
                    {
                        permission: 'Add IVR',
                    },
                ],

                last_updated: "Nov 4 2021, 10:15 PM",
            },
            {
                id: '3',
                role_type: 'Customer',
                permissions: [
                    {
                        permission: 'View IVR',

                    },
                    {
                        permission: 'Edit IVR',
                    },
                    {
                        permission: 'Add IVR',
                    },
                ],

                last_updated: "Nov 4 2021, 10:15 PM",
            },



        ])
    },[]);
    const [deleteRoleMessage , setDeleteRoleMessage] = useState(false);
    const handleDeleteRoleMessage = () => {
        setDeleteRoleMessage(true);
        setTimeout(() => {
            setDeleteRoleMessage(false);

        }, 5000);
    }
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const handleOpenDeleteModal = () => {
        setOpenDeleteModal(true);
    }
    return(
        <>
            <div className="user_container bordered-table">
            <TableHeaderRoles />
            <div className="user_management">
                {getData.map((data) => (

                    <DataRowRoles data={data} element={handleOpenRolesModal} deleteModal={handleOpenDeleteModal} />
                ))}
                <div className={`message-alert ${deleteRoleMessage === true ? "show-message" : ""}`}>
                    <span className="icon-check-circle"></span>
                    <span className="message-text">Roles has been deleted successfully.</span>
                    <a href="javascript:void(0)" { ...( { onClick: () => setDeleteRoleMessage(false) } ) } className="icon-Union1"></a>
                </div>
            </div>
            </div>

            {/* <DataRowUser />
    <DataRowUser /> */}
            {
                openRolesModal ? <RolesModal  /> : ''
            }
            {
                openDeleteModal ? <DeleteRolesModal element={handleDeleteRoleMessage} /> : ''
            }
        </>
    )
}
export default RolesManagement;


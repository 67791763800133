import React, {useState} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimeList from "../SmoothScroll/TimeList";

const ScheduleModal = () => {
    const screensize = window.innerWidth;
    const [showSchedule, setShowSchedule] = useState(false);
    const [startDate, setDate] = React.useState(new Date)
    const [rangeStart, setRangeStart] = React.useState(new Date)
    const defaultEndDate = new Date()
    defaultEndDate.setDate(defaultEndDate.getDate() + 7)
    const [rangeEnd, setRangeEnd] = React.useState(defaultEndDate)
    const today = new Date()

    const selectDateHandler = (d) => {
        setDate(d)
    }

    const selectStartDate = d => {
        setRangeStart(d)
    }

    const selectEndDate = d => {
        setRangeEnd(d)
    }

    return (
        <div className={`modal ivr-modal ${screensize > 992 ? "fade" : "fade-mob"}`} id="scheduleModal" tabIndex="-1" role="dialog" aria-labelledby="ScheduleModal"
             aria-hidden="true" >
            <div className={`modal-dialog  ${screensize > 992 ? "modal-dialog-centered" : "modal-lg"} `} role="document">
                <div  className={`modal-content ${showSchedule === true ? "d-none" : "d-block"} `}>
                    <div className="modal-header">
                        <h2 className="modal-title" id="addIVRLabel">Schedule IVR
                            <small>Please select data & time to schedule IVR.</small>
                        </h2>



                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-row">
                                <div className="col-12 col-md-6 dropDownField forms-field">
                                    <label htmlFor="versionName">Schedule Revisions<span
                                        className="mand-field">*</span></label>
                                    <input id="versionName" type="text" placeholder="Credit IVR Version"
                                           className="form-control"/>
                                </div>
                                <div className="col-12 col-md-6 dropDownField forms-field">
                                    <label htmlFor="timezone">Current Timezone<span className="mand-field">*</span></label>
                                    <input id="timezone" type="text" placeholder="PST Friday, 03:44:22"
                                           className="form-control"/>
                                </div>
                                <div className="col-12 col-md-6 dateField forms-field">
                                    <label htmlFor="date">Select Date <span
                                        className="mand-field">*</span></label>
                                    <DatePicker
                                        dateFormat="dd,mm yyyy"
                                        selected={startDate}
                                        onChange={selectDateHandler}
                                        calendarClassName="ivr-calendar"
                                    />
                                </div>
                                <div className="col-12 col-md-6 timefield forms-field">
                                    <label htmlFor="time">Select Date <span
                                        className="mand-field">*</span></label>
                                   <TimeList />
                                </div>
                            </div>




                        </form>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-primary" { ...( { onClick: () => setShowSchedule(!showSchedule) } ) }>Save</button>
                    </div>
                </div>
                <div className={`modal-content schedule-modal delete-modal ${showSchedule === true ? "d-block" : ""} `}>

                    <div className="modal-body schedule-changes">
                        <div className="schedule-icon">
                            <span className="icon-calender-icon-new"></span>
                        </div>
                        <h1>Schedule Changes</h1>
                        <h3>Are you sure you want to schedule changes?</h3>
                        <div className="text-with-bg">
                            <h4>Selected Version <span>Credit IVR Version, 24 Nov, 11:30AM  </span></h4>
                        </div>
                        <div className="text-with-bg">
                            <h4>Logged on <span>Nov 17, 2021 11:38:00 </span></h4>
                        </div>
                    </div>

                    <div className="modal-footer schedule-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-primary" data-dismiss="modal"  { ...( { onClick: () => setShowSchedule(!showSchedule) } ) }>Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScheduleModal;

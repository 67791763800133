import React from 'react'

const TableHeaderRoles = () => {
    return (
        <div className="container-fluid">
        <div className="row user_table ivr-grid-heads">
            <div className="col col-xl-1 col-lg-1 col-md-1 roles-type-grid">
                <span className="gridHeadText">Role Type</span>
            </div>
            <div className="col col-xl-7 col-lg-7 col-md-7 permissions-grid">
                <span className="gridHeadText">Permissions</span>
            </div>
            <div className="col col-xl-3 col-lg-3 col-md-2 last-activity-grid">
                <span className="gridHeadText">Last Activity</span>
            </div>
            <div className="col col-xl-1 col-lg-1 col-md-2 actions-grid">
                <span className="gridHeadText">Actions</span>
            </div>

        </div>
        </div>

    )
}

export default TableHeaderRoles;
